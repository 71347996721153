import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import CreditLimitErrorsObjectAttributes from "../../../domain/credit-limit/CreditLimitErrorsObjectAttributes"

export default class NetworkCreditLimitErrorsObjectAttributes implements CreditLimitErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly value?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly periodType?: NetworkAttributeError[] | null
}
