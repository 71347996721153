import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import FuelPricesI18n from "../../i18n/FuelPricesI18n"
import FuelPrice from "../../../../core/domain/fuel-prices/FuelPrice"
import DateInfoRow from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/DateInfoRow"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class FuelPriceInfoProvider implements InfoProvider<FuelPrice> {
  private readonly timeZone: string
  private readonly fuelPricesI18n: FuelPricesI18n

  constructor(parameters: {
    readonly timeZone: string
    readonly fuelPricesI18n: FuelPricesI18n
  }) {
    this.timeZone = parameters.timeZone
    this.fuelPricesI18n = parameters.fuelPricesI18n
  }

  getEntity(): string {
    return Entity.FUEL_PRICES
  }

  getObjectShortTitle(): string {
    return this.fuelPricesI18n.getTextProvider().existObjectTitle()
  }

  getObjectTitle(): string {
    return this.fuelPricesI18n.getTextProvider().existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: FuelPrice
  }): InfoRow[] {
    const fuelPricesTextProvider = this.fuelPricesI18n.getTextProvider()
    const appUrlProvider = new AppUrlProvider()

    return [
      new StringInfoRow({
        name: "gasStation",
        entity: Entity.GAS_STATIONS,
        title: fuelPricesTextProvider.gasStationNameField(),
        value: object?.gasStation?.name,
        url: object.gasStation && appUrlProvider.buildShowGasStationUrl({
          gasStationId: object.gasStation.id!
        })
      }),
      new StringInfoRow({
        name: "fuelType",
        title: fuelPricesTextProvider.fuelTypeField(),
        value: object?.fuel?.name
      }),
      new DateInfoRow({
        name: "startDate",
        title: fuelPricesTextProvider.startDatePriceField(),
        value: object.startDate,
        format: DateTimeFormat.DATE_TIME,
        timeZone: this.timeZone
      }),
      new StringInfoRow({
        name: "value",
        title: fuelPricesTextProvider.priceField(),
        value: object.formattedValue && withRubbleSymbol(object.formattedValue)
      })
    ]
  }

  getLinks(): LinkInfo[] {
    return []
  }

  getEditUrl({
    object
  }: {
    readonly object: FuelPrice
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditFuelPriceUrl({
      fuelPriceId: object.id!
    })
  }
}
