import CarriersMapper from "./CarriersMapper"
import FuelCompaniesMapper from "./FuelCompaniesMapper"
import FuelsMapper from "./FuelsMapper"
import GasStationsMapper from "./GasStationsMapper"
import NetworkRefuellingOrdersReport from "../entities/refuelling-orders-reports/NetworkRefuellingOrdersReport"
import RefuellingOrdersReport from "../../domain/refuelling-orders-reports/RefuellingOrdersReport"
import UsersMapper from "./UsersMapper"
import { RefuellingOrderStatusSelectOption } from "../../domain/refuelling-orders/RefuellingOrderStatus"
import TripsMapper from "./TripsMapper"

export default class RefuellingOrdersReportsMapper {
  mapNetworkToDomain({
    report,
    refuellingOrderStatuses
  }: {
    readonly report: NetworkRefuellingOrdersReport,
    readonly refuellingOrderStatuses: RefuellingOrderStatusSelectOption[]
  }): RefuellingOrdersReport {
    const {
      carriers,
      fuelCompanies,
      gasStations,
      fuels,
      trips,
      drivers
    } = report

    const fuelCompaniesMapper = new FuelCompaniesMapper()
    const carriersMapper = new CarriersMapper()
    const gasStationsMapper = new GasStationsMapper()
    const tripsMapper = new TripsMapper()
    const usersMapper = new UsersMapper()
    const fuelsMapper = new FuelsMapper()

    return {
      id: report.id,
      createdAt: report.createdAt,
      fromDate: report.fromDate,
      toDate: report.toDate,
      fuelCompanyIds: report.fuelCompanyIds,
      fuelCompanies: fuelCompanies && fuelCompanies.map(fuelCompany => {
        return fuelCompaniesMapper.mapNetworkToDomain({
          fuelCompany,
          creditLimitPeriodTypeSelectOptions: []
        })
      }),
      carrierIds: report.carrierIds,
      carriers: carriers && carriers.map(carrier => {
        return carriersMapper.mapNetworkToDomain({ carrier })
      }),
      gasStationIds: report.gasStationIds,
      gasStations: gasStations && gasStations.map(gasStation => {
        return gasStationsMapper.mapNetworkToDomain({ gasStation })
      }),
      tripIds: report.tripIds,
      trips: trips && trips.map(trip => {
        return tripsMapper.mapNetworkToDomain({ trip: trip, tripStatuses: [] })
      }),
      driverIds: report.driverIds,
      drivers: drivers && drivers.map(driver => {
        return usersMapper.mapNetworkToDomain({ user: driver })
      }),
      fuelIds: report.fuelIds,
      fuels: fuels && fuels.map(fuel => {
        return fuelsMapper.mapNetworkToDomain({ fuel })
      }),
      refuellingOrderStatuses: report.refuellingOrderStatuses,
      refuellingOrderStatusesSelectOptions: refuellingOrderStatuses.filter((option) => {
        return report.refuellingOrderStatuses?.includes(option.id)
      }),
      statusName: report.statusName,
      documentUrl: report.documentUrl,
      needRefresh: report.needRefresh
    }
  }

  mapDomainToNetwork({
    report
  }: {
    readonly report: RefuellingOrdersReport
  }): NetworkRefuellingOrdersReport {
    return {
      fromDate: report.fromDate,
      toDate: report.toDate,
      fuelCompanyIds: report.fuelCompanyIds,
      carrierIds: report.carrierIds,
      gasStationIds: report.gasStationIds,
      tripIds: report.tripIds,
      driverIds: report.driverIds,
      fuelIds: report.fuelIds,
      refuellingOrderStatuses: report.refuellingOrderStatuses
    }
  }
}
