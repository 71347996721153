import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import GasStationBreaksI18n from "../../i18n/GasStationBreaksI18n"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import GetGasStationsForGasStationBreaksUseCase from "../../domain/use-cases/gas-stations/GetGasStationsForGasStationBreaksUseCase"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GasStationBreakErrorsObject from "../../../../core/domain/gas-station-breaks/GasStationBreakErrorsObject"
import GasStationBreakError from "../../../../core/domain/gas-station-breaks/GasStationBreakError"
import GasStationBreak from "../../../../core/domain/gas-station-breaks/GasStationBreak"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"

const mainGroupName = "main"

export default class GasStationBreakFormProvider
  implements FormProvider<GasStationBreak, GasStationBreakError, GasStationBreakErrorsObject> {
  private readonly timeZone: string
  private readonly gasStationBreaksI18n: GasStationBreaksI18n
  private readonly getGasStationsUseCase: GetGasStationsForGasStationBreaksUseCase

  constructor(parameters: {
    readonly timeZone: string
    readonly gasStationBreaksI18n: GasStationBreaksI18n
    readonly getGasStationsUseCase: GetGasStationsForGasStationBreaksUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.gasStationBreaksI18n = parameters.gasStationBreaksI18n
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
  }

  getEntity(): string {
    return Entity.GAS_STATION_BREAKS
  }

  getNewObjectTitle(): string {
    return this.gasStationBreaksI18n.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle(): string {
    return this.gasStationBreaksI18n.getTextProvider().existObjectTitle()
  }

  getExistedObjectTitle(): string {
    return this.gasStationBreaksI18n.getTextProvider().existObjectTitle()
  }

  async buildObject(): Promise<GasStationBreak> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: GasStationBreakError
  }): GasStationBreakErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<GasStationBreak, GasStationBreakErrorsObject>[] {
    const gasStationBreaksTextProvider = this.gasStationBreaksI18n.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<GasStationBreak, GasStationBreakErrorsObject, GasStation>({
        title: gasStationBreaksTextProvider.gasStationNameField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getGasStationsUseCase,
        getErrors: (errorsObject?: GasStationBreakErrorsObject) => errorsObject?.attributes?.gasStationId,
        getId: () => "gasStationId",
        getOptionId: (gasStation: GasStation) => gasStation.id!.toString(),
        getOptionText: (gasStation: GasStation) => gasStation.name,
        getValue: (gasStationBreak: GasStationBreak) => gasStationBreak.gasStation,
        setValue: (gasStationBreak: GasStationBreak, gasStation: GasStation | null) => ({
          ...gasStationBreak, gasStation, gasStationId: gasStation?.id
        })
      }),
      new DateFormField<GasStationBreak, GasStationBreakErrorsObject>({
        title: gasStationBreaksTextProvider.beginDateTimeField(),
        groupName: mainGroupName,
        withTime: true,
        required: true,
        timeZone: this.timeZone,
        getId: () => "beginDateTime",
        clearable: false,
        getValue: (gasStationBreak: GasStationBreak) => gasStationBreak.beginDateTime,
        setValue: (
          gasStationBreak: GasStationBreak,
          beginDateTime: Date | null | undefined
        ): GasStationBreak => {
          return {
            ...gasStationBreak, beginDateTime
          }
        },
        getErrors: (errorsObject?: GasStationBreakErrorsObject) => errorsObject?.attributes?.beginDateTime
      }),
      new DateFormField<GasStationBreak, GasStationBreakErrorsObject>({
        title: gasStationBreaksTextProvider.endDateTimeField(),
        groupName: mainGroupName,
        withTime: true,
        required: true,
        getId: () => "endDateTime",
        timeZone: this.timeZone,
        clearable: false,
        getValue: (gasStationBreak: GasStationBreak) => gasStationBreak.endDateTime,
        setValue: (
          gasStationBreak: GasStationBreak,
          endDateTime: Date | null | undefined
        ): GasStationBreak => {
          return {
            ...gasStationBreak, endDateTime
          }
        },
        getErrors: (errorsObject?: GasStationBreakErrorsObject) => errorsObject?.attributes?.endDateTime
      }),
      new StringFormField<GasStationBreak, GasStationBreakErrorsObject>({
        title: gasStationBreaksTextProvider.commentField(),
        groupName: mainGroupName,
        getId: () => "comment",
        getValue: (gasStationBreak: GasStationBreak) => gasStationBreak.comment,
        setValue: (gasStationBreak: GasStationBreak, comment: string) => {
          return {
            ...gasStationBreak,
            comment
          }
        }
      })
    ])
  }
}
