import AppI18 from "../../../i18n/AppI18"
import isPresent from "../../../../../admin/lib/isPresent"
import CreditLimitPeriodType, { CreditLimitPeriodTypeSelectOption } from "../../../domain/credit-limit/CreditLimitPeriodType"

export default class CreditLimitPeriodTypesStaticSource {
  private readonly appI18n: AppI18

  constructor(parameters: {
    readonly appI18n: AppI18
  }) {
    this.appI18n = parameters.appI18n
  }

  async getCreditLimitPeriodTypes({
    ids
  }: {
    readonly ids?: string[] | null
  }): Promise<CreditLimitPeriodTypeSelectOption[]> {
    const values = [
      {
        id: CreditLimitPeriodType.ONE_MONTH,
        text: this.appI18n.getTextProvider().creditLimitPeriodTypeOneMonthText()
      },
      {
        id: CreditLimitPeriodType.HALF_OF_MONTH,
        text: this.appI18n.getTextProvider().creditLimitPeriodTypeHalfOfMonthText()
      },
      {
        id: CreditLimitPeriodType.THIRD_OF_MONTH,
        text: this.appI18n.getTextProvider().creditLimitPeriodTypeThirdOfMonthText()
      },
      {
        id: CreditLimitPeriodType.ONE_WEEK,
        text: this.appI18n.getTextProvider().creditLimitPeriodTypeOneWeekText()
      }
    ]

    if (isPresent(ids)) {
      return values.filter((value) => ids.includes(value.id))
    }

    return values
  }
}
