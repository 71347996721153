import React from "react"
import FuelCompanyBalanceView, {
  FuelCompanyBalanceHeaderViewState, FuelCompanyBalanceListItem, FuelCompanyBalanceListViewState
} from "./FuelCompanyBalanceView"
import FuelCompanyBalancePresenter from "./FuelCompanyBalancePresenter"
import FuelCompanyBalancesI18n from "../../../i18n/FuelCompanyBalancesI18n"
import HelmetComponent from "../../../../../../admin/core/presentation/components/helmet/HelmetComponent"
import BalanceHeaderComponent from "../../../../../core/presentation/components/balance-header/BalanceHeaderComponent"
import isPresent from "../../../../../../admin/lib/isPresent"
import isBlank from "../../../../../../admin/lib/isBlank"
import { SegmentControlItem } from "../../../../../../admin/lib/segment-control/SegmentControlComponent"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import TableComponent from "../../../../../../admin/features/objects/presentation/components/table/TableComponent"
import ExecutionError from "../../../../../../admin/core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../../admin/core/domain/exceptions/ApplicationException"
import AppUrlProvider from "../../../../../core/presentation/services/AppUrlProvider"
import { Navigate } from "react-router-dom"
import AppI18 from "../../../../../core/i18n/AppI18"
import withRubbleSymbol from "../../../../../../admin/lib/withRubbleSymbol"

interface Props {
  readonly fuelCompanyBalancesI18n: FuelCompanyBalancesI18n
  readonly appI18n: AppI18
  readonly providePresenter: () => FuelCompanyBalancePresenter
  readonly cacheTransactionsType: (transactionsType: string) => void
}

interface State {
  readonly fuelCompanyBalanceListViewState?: FuelCompanyBalanceListViewState
  readonly fuelCompanyBalanceHeaderViewState?: FuelCompanyBalanceHeaderViewState
  readonly paddingTop?: number
}

export default class FuelCompanyBalancePage
  extends React.Component<Props, State>
  implements FuelCompanyBalanceView {

  private readonly presenter: FuelCompanyBalancePresenter
  private readonly fuelCompanyBalancesI18n: FuelCompanyBalancesI18n
  private readonly appI18n: AppI18

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.appI18n = props.appI18n
    this.presenter = props.providePresenter()
    this.fuelCompanyBalancesI18n = props.fuelCompanyBalancesI18n
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showFuelCompanyBalanceListViewState(
    fuelCompanyBalanceListViewState: FuelCompanyBalanceListViewState
  ): void {
    this.setState({
      fuelCompanyBalanceListViewState
    })
  }

  showFuelCompanyBalanceHeaderViewState(
    fuelCompanyBalanceHeaderViewState: FuelCompanyBalanceHeaderViewState
  ): void {
    this.setState({
      fuelCompanyBalanceHeaderViewState
    })
  }

  cacheSelectedTransactionsType(transactionsType: string): void {
    this.props.cacheTransactionsType(transactionsType)
  }

  render() {
    const {
      fuelCompanyBalanceListViewState,
      fuelCompanyBalanceHeaderViewState,
      paddingTop
    } = this.state

    const appUrlProvider = new AppUrlProvider()
    if (fuelCompanyBalanceListViewState?.type === "forbidden") {
      return <Navigate to={appUrlProvider.buildForbiddenUrl()} replace={true} />
    }

    if (
      isBlank(fuelCompanyBalanceHeaderViewState) ||
      isBlank(fuelCompanyBalanceListViewState)
    ) return <></>

    const fuelCompanyBalancesTextProvider =
      this.fuelCompanyBalancesI18n.getTextProvider()

    const fuelCompany = (() => {
      switch (fuelCompanyBalanceHeaderViewState.type) {
        case "loaded": {
          return fuelCompanyBalanceHeaderViewState.fuelCompany
        }
        default:
          return null
      }
    })()

    const {
      table
    } = fuelCompanyBalanceListViewState

    const pageTitle = isPresent(fuelCompany) ?
      fuelCompanyBalancesTextProvider.listTitle({
        fuelCompanyName: fuelCompany.name,
        fuelCompanyBalance: fuelCompany.balance?.formattedValue
      }) : fuelCompanyBalancesTextProvider.listTitle()

    const segmentControlItems: SegmentControlItem[] = [
      {
        id: BalanceTransactionType.REPLENISHMENT,
        text: fuelCompanyBalancesTextProvider.replenishmentsSegment()
      },
      {
        id: BalanceTransactionType.WRITE_OFF,
        text: fuelCompanyBalancesTextProvider.writeOffsSegment()
      }
    ]

    const selectedSegmentControlItems = segmentControlItems
      .find((segmentControlItem) => segmentControlItem.id === fuelCompanyBalanceHeaderViewState.transactionType)

    const items: FuelCompanyBalanceListItem[] = (() => {
      switch (fuelCompanyBalanceListViewState.type) {
        case "loaded":
        case "next_page_loading":
        case "next_page_loading_error":
        case "next_page_loading_failure": {
          return fuelCompanyBalanceListViewState.items
        }
        default:
          return []
      }
    })()

    const transactionsLoadingError: ExecutionError | undefined = (() => {
      switch (fuelCompanyBalanceListViewState.type) {
        case "loading_error":
        case "next_page_loading_error": {
          return fuelCompanyBalanceListViewState.error
        }
        default:
          return undefined
      }
    })()

    const transactionsLoadingException: ApplicationException | undefined = (() => {
      switch (fuelCompanyBalanceListViewState.type) {
        case "loading_failure":
        case "next_page_loading_failure": {
          return fuelCompanyBalanceListViewState.exception
        }
        default:
          return undefined
      }
    })()

    const transactionsIsLoading = fuelCompanyBalanceListViewState.type === "initializing" ||
      fuelCompanyBalanceListViewState.type === "loading" ||
      fuelCompanyBalanceListViewState.type === "next_page_loading"

    return (
      <>
        <HelmetComponent
          title={pageTitle}
        />
        {fuelCompanyBalanceHeaderViewState.type !== "initializing" && (
          <BalanceHeaderComponent
            ownerName={fuelCompany?.name ?? pageTitle}
            formattedBalanceValue={withRubbleSymbol(fuelCompany?.balance?.formattedValue)}
            onChangeHeaderHeight={(height) => {
              this.setState({
                paddingTop: height
              })
            }}
            subtitle={(() => {
              if (isPresent(fuelCompany?.formattedOverall)) {
                return this.appI18n.getTextProvider().creditLimitOverall({
                  formattedCreditLimit: fuelCompany?.creditLimit?.formattedValue,
                  formattedValue: fuelCompany?.formattedOverall
                })
              }

              if (isPresent(fuelCompany?.formattedDebt)) {
                return this.appI18n.getTextProvider().creditLimitDebt({
                  formattedValue: fuelCompany?.formattedDebt
                })
              }

              return ""
            })()}
            needShowAction={table.getCanCreateNewObject() === true}
            action={{
              url: fuelCompany && appUrlProvider.buildFuelCompanyBalanceChangeDocumentUrl({
                fuelCompanyId: fuelCompany.id!
              }),
              name: fuelCompanyBalancesTextProvider.writeOffBalance()
            }}
            segmentControlItems={segmentControlItems}
            selectedSegmentControlItem={selectedSegmentControlItems}
            onSelectSegmentControlItem={(segmentControlItem) => {
              this.presenter.onSegmentSelected(segmentControlItem.id)
            }}
          />
        )}
        <TableComponent
          paddingTop={paddingTop}
          table={table}
          objects={items}
          isLoading={transactionsIsLoading}
          exception={transactionsLoadingException}
          error={transactionsLoadingError}
          onNextPageRequested={this.presenter.onNextPageRequested}
          onRetryLoadClicked={this.presenter.onRetryLoadClicked}
        />
      </>
    )
  }
}
