import { Expose, Transform, Type } from "class-transformer"
import NetworkContactPerson from "../contact-people/NetworkContactPerson"
import NetworkLegalEntity from "../legal-entities/NetworkLegalEntity"
import NetworkFuelCompanyBalance from "../fuel-company-balances/NetworkFuelCompanyBalance"
import NetworkCreditLimit from "../credit-limits/NetworkCreditLimit"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"

export default class NetworkFuelCompany {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly code?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly blocked?: boolean | null

  @Expose()
  @Type(() => NetworkLegalEntity)
  readonly legalEntity?: NetworkLegalEntity | null

  @Expose()
  @Type(() => NetworkContactPerson)
  readonly contactPerson?: NetworkContactPerson | null

  @Expose()
  @Type(() => NetworkFuelCompanyBalance)
  readonly balance?: NetworkFuelCompanyBalance | null

  @Expose()
  @Type(() => NetworkCreditLimit)
  readonly creditLimit?: NetworkCreditLimit | null

  @Expose()
  @Transform(transformDecimal)
  readonly overall?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedOverall?: string | null

  @Expose()
  @Transform(transformDecimal)
  readonly debt?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedDebt?: string | null
}
