import LegalEntitiesMapper from "./LegalEntitiesMapper"
import ContactPeopleMapper from "./ContactPeopleMapper"
import FuelCompany from "../../domain/fuel-companies/FuelCompany"
import NetworkFuelCompany from "../entities/fuel-companies/NetworkFuelCompany"
import FuelCompanyBalancesMapper from "./FuelCompanyBalancesMapper"
import CreditLimitsMapper from "./CreditLimitsMapper"
import { CreditLimitPeriodTypeSelectOption } from "../../domain/credit-limit/CreditLimitPeriodType"

export default class FuelCompaniesMapper {
  mapNetworkToDomain({
    fuelCompany,
    creditLimitPeriodTypeSelectOptions
  }: {
    readonly fuelCompany: NetworkFuelCompany
    readonly creditLimitPeriodTypeSelectOptions: CreditLimitPeriodTypeSelectOption[]
  }): FuelCompany {
    const {
      legalEntity,
      contactPerson,
      balance: fuelCompanyBalance,
      creditLimit
    } = fuelCompany

    return {
      id: fuelCompany.id,
      name: fuelCompany.name,
      code: fuelCompany.code,
      blocked: fuelCompany.blocked,
      legalEntity: legalEntity && new LegalEntitiesMapper().mapNetworkToDomain({ legalEntity }),
      contactPerson: contactPerson && new ContactPeopleMapper().mapNetworkToDomain({ contactPerson }),
      balance: fuelCompanyBalance && new FuelCompanyBalancesMapper().mapNetworkToDomain({ fuelCompanyBalance }),
      creditLimit: creditLimit && new CreditLimitsMapper().mapNetworkToDomain({
        creditLimit,
        creditLimitPeriodTypeSelectOptions
      }),
      debt: fuelCompany.debt,
      formattedDebt: fuelCompany.formattedDebt,
      overall: fuelCompany.overall,
      formattedOverall: fuelCompany.formattedOverall
    }
  }

  mapDomainToNetwork({
    fuelCompany
  }: {
    readonly fuelCompany: FuelCompany
  }): NetworkFuelCompany {
    const { legalEntity, contactPerson, creditLimit } = fuelCompany
    return {
      name: fuelCompany.name,
      code: fuelCompany.code,
      blocked: fuelCompany.blocked,
      legalEntity: legalEntity && new LegalEntitiesMapper().mapDomainToNetwork({ legalEntity }),
      contactPerson: contactPerson && new ContactPeopleMapper().mapDomainToNetwork({ contactPerson }),
      creditLimit: creditLimit && new CreditLimitsMapper().mapDomainToNetwork({ creditLimit })
    }
  }
}
