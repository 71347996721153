import React, { useEffect, useRef, useState } from "react"
import styles from "./TextInputComponent.module.scss"
import isPresent from "../isPresent"
import TextInputComponentStyle from "./TextInputComponentStyles"
import { IMaskInput } from "react-imask"
import isBlank from "../isBlank"

const defaultPadding = 8

export function TimeInputComponent({
  name,
  value = "",
  isDisabled = false,
  hasErrors = false,
  isActive = false,
  placeholder = "",
  className = "",
  componentStyle = {},
  onChange = () => undefined,
  onKeyDown = () => undefined,
  onClick = () => undefined
}: {
  readonly name?: string
  readonly value?: string | null
  readonly isDisabled?: boolean
  readonly isActive?: boolean
  readonly placeholder?: string
  readonly hasErrors?: boolean
  readonly className?: string
  readonly componentStyle?: TextInputComponentStyle
  readonly onChange?: (value: string) => void
  readonly onKeyDown?: (key: React.KeyboardEvent<HTMLInputElement>) => void
  readonly onClick?: () => void
}) {
  const [isFocused, setFocused] = useState(false)
  const [leftIconWidth, setLeftIconWidth] = useState(defaultPadding)
  const [rightIconWidth, setRightIconWidth] = useState(defaultPadding)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const leftIconDivRef = useRef<HTMLDivElement>(null)
  const rightIconDivRef = useRef<HTMLDivElement>(null)

  const currentlyActive = isFocused || isActive

  const {
    classes: {
      inputDefault: inputDefaultClassName = "",
      inputActive: inputActiveClassName = "",
      inputErrors: inputErrorsClassName = ""
    } = {},
    icons = {}
  } = componentStyle

  const leftIcon = (() => {
    if (isDisabled) {
      return icons.leftIconDisabled ?? icons.leftIconDefault
    }

    if (currentlyActive) {
      return icons.leftIconActive ?? icons.leftIconDefault
    }

    return icons.leftIconDefault
  })()

  const rightIcon = (() => {
    if (isDisabled) {
      return icons.rightIconDisabled ?? icons.rightIconDefault
    }

    if (currentlyActive) {
      return icons.rightIconActive ?? icons.rightIconDefault
    }

    return icons.rightIconDefault
  })()

  // TODO: Think how to do it without useEffect
  useEffect(() => {
    if (isPresent(leftIconDivRef.current)) {
      setLeftIconWidth(leftIconDivRef.current.clientWidth)
    }

    if (isPresent(rightIconDivRef.current)) {
      setRightIconWidth(rightIconDivRef.current.clientWidth)
    }
  }, [])

  function handleTimeChanged(newValue: string) {
    if (newValue === value) return

    onChange(newValue)
  }

  return (
    <div className={`${styles.root} ${className}`}>
      {leftIcon && (
        <div
          className={`${styles.icon} ${styles.left}`}
          ref={leftIconDivRef}
        >
          {leftIcon}
        </div>
      )}
      <IMaskInput
        lazy={true}
        mask="HH:mm"
        overwrite={true}
        commit={(value, masked) => {
          if (value.length < 4) {
            const components = value.split(":")
            const hours = (components[0] ?? "").padEnd(2, "0")

            const minutes = (components[1] ?? "").padEnd(2, "0")
            masked.value = `${hours}:${minutes}`
          }
        }}
        blocks={{
          "HH": {
            mask: "00",
            prepareChar: (character, masked) => {
              const maskedValue = masked.value
              if (maskedValue.length === 2) return character

              if (isBlank(character)) return character
              const number = parseInt(character)
              if (isNaN(number)) return character

              if (number < 0) return "0"

              if (maskedValue.length === 0) {
                return number > 2 ? "2" : character
              }

              if (maskedValue.length === 1) {
                const currentValueNumber = parseInt(maskedValue)
                if (isNaN(currentValueNumber)) return character

                if (currentValueNumber === 2) {
                  return number > 3 ? "3" : character
                }
              }

              return character
            }
          },
          "mm": {
            mask: "00",
            prepareChar: (character, masked) => {
              const maskedValue = masked.value
              if (maskedValue.length === 2) return character

              if (isBlank(character)) return character
              const number = parseInt(character)
              if (isNaN(number)) return character

              if (number < 0) return "0"

              if (maskedValue.length === 0) {
                return number > 5 ? "5" : character
              }

              return character
            }
          }
        }}
        id={name}
        ref={inputRef}
        className={`${styles.input} ${inputDefaultClassName} ${hasErrors ? inputErrorsClassName : ""} ${currentlyActive ? inputActiveClassName : ""}`}
        type="tel"
        value={value ?? ""}
        placeholder={placeholder}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onComplete={(value) => handleTimeChanged(value)}
        onAccept={(value) => {
          if (isBlank(value)) {
            handleTimeChanged("00:00")
          }
        }}
        disabled={isDisabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          paddingLeft: leftIconWidth,
          paddingRight: rightIconWidth
        }}
      />
      {rightIcon && (
        <div
          className={`${styles.icon} ${styles.right}`}
          ref={rightIconDivRef}
        >
          {rightIcon}
        </div>
      )}
    </div>
  )
}
