import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn, {
  TableColumnAlignment
} from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import isBlank from "../../../../../admin/lib/isBlank"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import FuelCompaniesI18 from "../../i18n/FuelCompaniesI18"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import formatPhoneNumber from "../../../../../admin/lib/formatPhone"

export default class FuelCompaniesTableProvider implements TableProvider<FuelCompany> {
  private readonly fuelCompaniesI18: FuelCompaniesI18

  constructor(parameters: {
    readonly fuelCompaniesI18: FuelCompaniesI18
  }) {
    this.fuelCompaniesI18 = parameters.fuelCompaniesI18
  }

  getEntity(): string {
    return Entity.FUEL_COMPANIES
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<FuelCompany> {
    const appUrlProvider = new AppUrlProvider()
    const fuelCompaniesTextProvider = this.fuelCompaniesI18.getTextProvider()

    return new Table<FuelCompany>({
      title: fuelCompaniesTextProvider.listTitle(),
      getObjectId: (fuelCompany: FuelCompany) => fuelCompany.id!.toString(),
      getRowUrl: (fuelCompany: FuelCompany) => appUrlProvider.buildShowFuelCompanyUrl({
        fuelCompanyId: fuelCompany.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewFuelCompanyUrl(),
      columns: [
        new TableColumn<FuelCompany>({
          name: "name",
          title: fuelCompaniesTextProvider.nameField(),
          isSortingAvailable: true,
          createValue: (fuelCompany: FuelCompany) => new TextTableValue({
            value: fuelCompany.name
          })
        }),
        new TableColumn<FuelCompany>({
          name: "balance",
          entity: Entity.FUEL_COMPANY_BALANCE_REPLENISHMENTS,
          title: fuelCompaniesTextProvider.balanceField(),
          columnAlignment: TableColumnAlignment.RIGHT,
          createValue: (fuelCompany: FuelCompany) => {
            const { balance } = fuelCompany
            const value = (() => {
              if (isBlank(balance)) return undefined

              const { formattedValue } = balance
              return formattedValue && withRubbleSymbol(formattedValue)
            })()

            return new TextTableValue({
              value,
              url: appUrlProvider.buildFuelCompanyBalanceUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })
          }
        }),
        new TableColumn<FuelCompany>({
          name: "contactPerson",
          title: fuelCompaniesTextProvider.contactPersonField(),
          createValue: (fuelCompany: FuelCompany) => {
            const additionalValue = (() => {
              const { contactPerson } = fuelCompany
              if (isBlank(contactPerson)) return null

              const { phoneNumber, email } = contactPerson
              return filterNotEmpty([
                formatPhoneNumber(
                  phoneNumber
                ),
                email
              ])
                .join(", ")
            })()

            return new TextTableValue({
              value: fuelCompany.contactPerson?.name,
              additionalValue
            })
          }
        }),
        new TableColumn<FuelCompany>({
          name: "legalEntity",
          title: fuelCompaniesTextProvider.legalEntityField(),
          createValue: (fuelCompany: FuelCompany) => {
            const additionalValue = (() => {
              const { legalEntity } = fuelCompany
              if (isBlank(legalEntity)) return null

              const { individualTaxpayerNumber, taxRegistrationReasonCode } = legalEntity
              return filterNotEmpty([
                individualTaxpayerNumber && `${fuelCompaniesTextProvider.legalEntityIndividualTaxpayerNumberField()}: ${individualTaxpayerNumber}`,
                taxRegistrationReasonCode && `${fuelCompaniesTextProvider.legalEntityTaxRegistrationReasonCode()}: ${taxRegistrationReasonCode}`
              ]).join(" ")
            })()

            return new TextTableValue({
              value: fuelCompany.legalEntity?.name,
              additionalValue
            })
          }
        }),
        new TableColumn<FuelCompany>({
          name: "blocked",
          title: fuelCompaniesTextProvider.statusField(),
          isSortingAvailable: true,
          reversedSortOrder: true,
          createValue: (fuelCompany: FuelCompany) => {
            const { blocked = false } = fuelCompany

            return new TextTableValue({
              value: (blocked ?? false) ?
                fuelCompaniesTextProvider.statusValueBlocked() :
                fuelCompaniesTextProvider.statusValueActive(),
              isActive: !fuelCompany.blocked
            })
          }
        })
      ]
    })
  }
}
