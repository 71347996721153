import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import FuelCompanyBalancesDomainDiModule from "./FuelCompanyBalancesDomainDiModule"
import FuelCompanyBalancesI18nDiModule from "./FuelCompanyBalancesI18nDiModule"
import FuelCompanyBalancePresenter from "../../presentation/pages/fuel-company-balance/FuelCompanyBalancePresenter"
import ObjectsDomainDiModule from "../../../../../admin/features/objects/di/modules/ObjectsDomainDiModule"
import FuelCompanyBalancePageRouteElement
  from "../../presentation/pages/fuel-company-balance/FuelCompanyBalancePageRouteElement"
import FuelCompanyBalanceChangeDocumentsTableProvider
  from "../../presentation/table-providers/FuelCompanyBalanceChangeDocumentsTableProvider"
import FuelCompanyBalanceTransactionsTableProvider
  from "../../presentation/table-providers/FuelCompanyBalanceTransactionsTableProvider"
import BalanceTransactionType from "../../../../core/domain/balance-transactions/BalanceTransactionType"
import FuelCompanyBalanceChangeDocumentFormPageRouteElement
  from "../../presentation/pages/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentFormPageRouteElement"
import FuelCompanyBalanceChangeDocumentFormPresenter
  from "../../presentation/pages/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentFormPresenter"
import FuelCompanyBalanceChangeDocumentFormProvider
  from "../../presentation/form-providers/FuelCompanyBalanceChangeDocumentFormProvider"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"

const moduleKey = "fuelCompanyBalances"

export default interface FuelCompanyBalancesPresentationDiModule {
  provideFuelCompanyBalancePage(): React.ReactNode

  provideFuelCompanyBalanceChangeDocumentPage(): React.ReactNode
}

export class DefaultFuelCompanyBalancesPresentationDiModule
  extends DiModule
  implements FuelCompanyBalancesPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly fuelCompanyBalancesI18nDiModule: FuelCompanyBalancesI18nDiModule
  private readonly fuelCompanyBalancesDomainDiModule: FuelCompanyBalancesDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly fuelCompanyBalancesI18nDiModule: FuelCompanyBalancesI18nDiModule
    readonly fuelCompanyBalancesDomainDiModule: FuelCompanyBalancesDomainDiModule
    readonly appI18nDiModule: AppI18nDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.appI18nDiModule = parameters.appI18nDiModule
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.fuelCompanyBalancesI18nDiModule = parameters.fuelCompanyBalancesI18nDiModule
    this.fuelCompanyBalancesDomainDiModule = parameters.fuelCompanyBalancesDomainDiModule
  }

  provideFuelCompanyBalancePage(): React.ReactNode {
    return (
      <FuelCompanyBalancePageRouteElement
        appI18n={this.appI18nDiModule.provideAppI18n()}
        fuelCompanyBalancesI18n={this.fuelCompanyBalancesI18nDiModule.provideFuelCompanyBalancesI18n()}
        providePresenter={({
          fuelCompanyId,
          transactionsType
        }) => this.provideFuelCompanyBalancePresenter({
          key: moduleKey,
          fuelCompanyId,
          transactionsType
        })}
      />
    )
  }

  protected provideFuelCompanyBalancePresenter({
    key,
    fuelCompanyId,
    transactionsType
  }: {
    readonly key: string
    readonly fuelCompanyId: string
    readonly transactionsType?: BalanceTransactionType | null
  }): FuelCompanyBalancePresenter {
    return new FuelCompanyBalancePresenter({
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      subscribeToObjectsEventsUseCase:
        this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
          key
        }),
      unsubscribeFromObjectsEventsUseCase:
        this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
          key
        }),
      getFuelCompanyBalanceTransactionsUseCase:
        this.fuelCompanyBalancesDomainDiModule.provideGetFuelCompanyBalanceTransactionsUseCase(),
      getFuelCompanyBalanceChangeDocumentsUseCase:
        this.fuelCompanyBalancesDomainDiModule.provideGetFuelCompanyBalanceChangeDocumentsUseCase(),
      getFuelCompanyUseCase:
        this.fuelCompanyBalancesDomainDiModule.provideGetFuelCompanyForFuelCompanyBalancesUseCase(),
      fuelCompanyId,
      transactionsType,
      fuelCompanyBalanceTransactionsTableProvider:
        this.provideFuelCompanyBalanceTransactionsTableProvider(),
      fuelCompanyBalanceChangeDocumentsTableProvider:
        this.provideFuelCompanyBalanceChangeDocumentsTableProvider()
    })
  }

  // eslint-disable-next-line max-len
  private provideFuelCompanyBalanceChangeDocumentsTableProvider(): FuelCompanyBalanceChangeDocumentsTableProvider {
    return this.single(
      FuelCompanyBalanceChangeDocumentsTableProvider.name,
      () => new FuelCompanyBalanceChangeDocumentsTableProvider({
        fuelCompanyBalancesI18n: this.fuelCompanyBalancesI18nDiModule.provideFuelCompanyBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }

  // eslint-disable-next-line max-len
  private provideFuelCompanyBalanceTransactionsTableProvider(): FuelCompanyBalanceTransactionsTableProvider {
    return this.single(
      FuelCompanyBalanceTransactionsTableProvider.name,
      () => new FuelCompanyBalanceTransactionsTableProvider({
        fuelCompanyBalancesI18n: this.fuelCompanyBalancesI18nDiModule.provideFuelCompanyBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }

  provideFuelCompanyBalanceChangeDocumentPage(): React.ReactNode {
    return (
      <FuelCompanyBalanceChangeDocumentFormPageRouteElement
        providePresenter={({ fuelCompanyId }) => this.provideFuelCompanyBalanceChangeDocumentFormPresenter({
          key: moduleKey,
          fuelCompanyId
        })}
      />
    )
  }

  protected provideFuelCompanyBalanceChangeDocumentFormPresenter({
    key,
    fuelCompanyId
  }: {
    readonly key: string
    readonly fuelCompanyId: string | number
  }): FuelCompanyBalanceChangeDocumentFormPresenter {
    return new FuelCompanyBalanceChangeDocumentFormPresenter({
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key
      }),
      formProvider: this.provideFuelCompanyBalanceChangeDocumentFormProvider(),
      createFuelCompanyBalanceChangeDocumentUseCase:
        this.fuelCompanyBalancesDomainDiModule.provideCreateFuelCompanyBalanceChangeDocumentUseCase(),
      fuelCompanyId
    })
  }

  private provideFuelCompanyBalanceChangeDocumentFormProvider(): FuelCompanyBalanceChangeDocumentFormProvider {
    return this.single(
      FuelCompanyBalanceChangeDocumentFormProvider.name,
      () => new FuelCompanyBalanceChangeDocumentFormProvider({
        fuelCompanyBalancesI18n: this.fuelCompanyBalancesI18nDiModule.provideFuelCompanyBalancesI18n()
      })
    )
  }
}
