import NetworkGasStationBreak from "../entities/gas-station-breaks/NetworkGasStationBreak"
import GasStationBreak from "../../domain/gas-station-breaks/GasStationBreak"
import GasStationsMapper from "./GasStationsMapper"

export default class GasStationBreaksMapper {
  mapNetworkToDomain({
    gasStationBreak
  }: {
    readonly gasStationBreak: NetworkGasStationBreak
  }): GasStationBreak {
    return {
      id: gasStationBreak.id,
      beginDateTime: gasStationBreak.beginDateTime,
      endDateTime: gasStationBreak.endDateTime,
      gasStationId: gasStationBreak.gasStationId,
      comment: gasStationBreak.comment,
      gasStation: gasStationBreak.gasStation && new GasStationsMapper().mapNetworkToDomain({
        gasStation: gasStationBreak.gasStation
      })
    }
  }

  mapDomainToNetwork({
    gasStationBreak,
    timeZone
  }: {
    readonly gasStationBreak: GasStationBreak
    readonly timeZone: string
  }): NetworkGasStationBreak {
    return new NetworkGasStationBreak({
      id: gasStationBreak.id,
      beginDateTime: gasStationBreak.beginDateTime,
      endDateTime: gasStationBreak.endDateTime,
      comment: gasStationBreak.comment,
      gasStationId: gasStationBreak.gasStationId,
      timeZone
    })
  }
}
