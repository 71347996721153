import CarriersMapper from "./CarriersMapper"
import FuelCompaniesMapper from "./FuelCompaniesMapper"
import FuelsMapper from "./FuelsMapper"
import GasStationsMapper from "./GasStationsMapper"
import NetworkRefuellingOrder from "../entities/refuelling-orders/NetworkRefuellingOrder"
import RefuellingOrder from "../../domain/refuelling-orders/RefuellingOrder"
import UserTripsMapper from "./UserTripsMapper"
import { RefuellingOrderStatusSelectOption } from "../../domain/refuelling-orders/RefuellingOrderStatus"
import ClaimsMapper from "./ClaimsMapper"
import FuelTransactionsMapper from "./FuelTransactionsMapper"

export default class RefuellingOrdersMapper {
  mapNetworkToDomain({
    refuellingOrder,
    refuellingOrderStatuses
  }: {
    readonly refuellingOrder: NetworkRefuellingOrder
    readonly refuellingOrderStatuses: RefuellingOrderStatusSelectOption[]
  }): RefuellingOrder {
    const {
      carrier,
      fuelCompany,
      gasStation,
      fuel,
      userTrip,
      currentTransaction,
      claim
    } = refuellingOrder

    return {
      id: refuellingOrder.id,
      code: refuellingOrder.code,
      createdAt: refuellingOrder.createdAt,
      carrierId: refuellingOrder.carrierId,
      fuelCount: refuellingOrder.fuelCount,
      fuelCountType: refuellingOrder.fuelCountType,
      fuelPrice: refuellingOrder.fuelPrice,
      formattedFuelPrice: refuellingOrder.formattedFuelPrice,
      status: refuellingOrder.status,
      statusSelectOption: refuellingOrderStatuses.find((option) => {
        return option.id === refuellingOrder.status
      }),
      statusName: refuellingOrder.statusName,
      statusColorHex: refuellingOrder.statusColorHex,
      userTripId: refuellingOrder.userTripId,
      fuelId: refuellingOrder.fuelId,
      fuel: fuel && new FuelsMapper().mapNetworkToDomain({ fuel }),
      gasStation: gasStation && new GasStationsMapper().mapNetworkToDomain({ gasStation }),
      carrier: carrier && new CarriersMapper().mapNetworkToDomain({ carrier }),
      fuelCompany: fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({
        fuelCompany,
        creditLimitPeriodTypeSelectOptions: []
      }),
      currentTransaction: currentTransaction && new FuelTransactionsMapper().mapNetworkToDomain(
        { fuelTransaction: currentTransaction }
      ),
      userTrip: userTrip && new UserTripsMapper().mapNetworkToDomain({ userTrip }),
      claim: claim && new ClaimsMapper().mapNetworkToDomain({ claim })
    }
  }

  mapDomainToNetwork({
    refuellingOrder
  }: {
    readonly refuellingOrder: RefuellingOrder
  }): NetworkRefuellingOrder {
    const { claim } = refuellingOrder
    return {
      claim: claim && new ClaimsMapper().mapDomainToNetwork({ claim })
    }
  }
}
