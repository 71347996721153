import { Expose, Transform, Type } from "class-transformer"
import { Decimal } from "decimal.js"
import transformDecimal from "../../network/transformers/transformDecimal"
import CreditLimitPeriodType from "../../../domain/credit-limit/CreditLimitPeriodType"

export default class NetworkCreditLimit {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Transform(transformDecimal)
  readonly value?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedValue?: string | null

  @Expose()
  @Type(() => String)
  readonly periodType?: CreditLimitPeriodType | null
}
