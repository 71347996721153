import { Expose, Transform, Type } from "class-transformer"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import transformDate from "../../network/transformers/transformDate"

export default class NetworkGasStationBreak {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Transform(transformDate)
  readonly beginDateTime?: Date | null

  @Expose()
  @Transform(transformDate)
  readonly endDateTime?: Date | null

  @Expose()
  @Type(() => String)
  readonly comment?: string | null

  @Expose()
  @Type(() => Number)
  readonly gasStationId?: number | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStation?: NetworkGasStation | null

  readonly timeZone: string | null | undefined

  constructor(parameters: {
    readonly id: number | null | undefined
    readonly beginDateTime: Date | null | undefined
    readonly endDateTime: Date | null | undefined
    readonly comment: string | null | undefined
    readonly gasStationId: number | null | undefined
    readonly timeZone: string | null | undefined
  } | null | undefined) {
    this.id = parameters?.id
    this.beginDateTime = parameters?.beginDateTime
    this.endDateTime = parameters?.endDateTime
    this.comment = parameters?.comment
    this.gasStationId = parameters?.gasStationId
    this.timeZone = parameters?.timeZone
  }
}
