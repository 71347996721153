import React, { useEffect, useRef, useState } from "react"
import { IMaskInput } from "react-imask"
import styles from "./TextInputComponent.module.scss"
import isPresent from "../isPresent"
import TextInputComponentStyle from "./TextInputComponentStyles"
import { maxAvailableDate, minAvailableDate } from "../date-picker/dateConstants"

const defaultPadding = 8

// TODO: Try to make mask more universal and remove code duplication with simple text input
export default function DateInputComponent({
  name,
  value = "",
  isDisabled = false,
  hasErrors = false,
  isActive = false,
  placeholder = "",
  className = "",
  componentStyle = {},
  onChange = () => undefined,
  onClick = () => undefined
}: {
  readonly name?: string
  readonly value?: string | null
  readonly isDisabled?: boolean
  readonly isActive?: boolean
  readonly placeholder?: string
  readonly hasErrors?: boolean
  readonly className?: string
  readonly componentStyle?: TextInputComponentStyle
  readonly onChange?: (value: string) => void
  readonly onClick?: () => void
}) {
  const [isFocused, setFocused] = useState(false)
  const [leftIconWidth, setLeftIconWidth] = useState(defaultPadding)
  const [rightIconWidth, setRightIconWidth] = useState(defaultPadding)

  const leftIconDivRef = useRef<HTMLDivElement>(null)
  const rightIconDivRef = useRef<HTMLDivElement>(null)

  const currentlyActive = isFocused || isActive

  const {
    classes: {
      inputDefault: inputDefaultClassName = "",
      inputActive: inputActiveClassName = "",
      inputErrors: inputErrorsClassName = ""
    } = {},
    icons = {}
  } = componentStyle

  const leftIcon = (() => {
    if (isDisabled) {
      return icons.leftIconDisabled ?? icons.leftIconDefault
    }

    if (currentlyActive) {
      return icons.leftIconActive ?? icons.leftIconDefault
    }

    return icons.leftIconDefault
  })()

  const rightIcon = (() => {
    if (isDisabled) {
      return icons.rightIconDisabled ?? icons.rightIconDefault
    }

    if (currentlyActive) {
      return icons.rightIconActive ?? icons.rightIconDefault
    }

    return icons.rightIconDefault
  })()

  // TODO: Think how to do it without useEffect
  useEffect(() => {
    if (isPresent(leftIconDivRef.current)) {
      setLeftIconWidth(leftIconDivRef.current.clientWidth)
    }

    if (isPresent(rightIconDivRef.current)) {
      setRightIconWidth(rightIconDivRef.current.clientWidth)
    }
  }, [])

  return (
    <div className={`${styles.root} ${className}`}>
      {leftIcon && (
        <div
          className={`${styles.icon} ${styles.left}`}
          ref={leftIconDivRef}
        >
          {leftIcon}
        </div>
      )}
      <IMaskInput
        id={name}
        mask={Date}
        autoComplete="off"
        min={minAvailableDate}
        max={maxAvailableDate}
        className={`${styles.input} ${inputDefaultClassName} ${hasErrors ? inputErrorsClassName : ""} ${currentlyActive ? inputActiveClassName : ""}`}
        type="text"
        value={value ?? ""}
        placeholder={placeholder}
        onClick={onClick}
        onComplete={(formattedValue) => {
          if (formattedValue === value) return

          onChange(formattedValue)
        }}
        disabled={isDisabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          paddingLeft: leftIconWidth,
          paddingRight: rightIconWidth,
          pointerEvents: isActive ? "none" : undefined
        }}
      />
      {rightIcon && (
        <div
          className={`${styles.icon} ${styles.right}`}
          ref={rightIconDivRef}
        >
          {rightIcon}
        </div>
      )}
    </div>
  )
}
