import FuelCompaniesTextProvider from "../FuelCompaniesTextProvider"

export default class DefaultRuFuelCompaniesTextProvider implements FuelCompaniesTextProvider {
  balanceLink(): string {
    return "История списаний и начислений"
  }

  balanceField(): string {
    return "Баланс"
  }

  leftField(): string {
    return "Осталось"
  }

  overflowField(): string {
    return "Превышен на"
  }

  blockedField(): string {
    return "Блокировка"
  }

  codeField(): string {
    return "Код"
  }

  contactPersonEmailField(): string {
    return "E-mail"
  }

  contactPersonField(): string {
    return "Контакты"
  }

  contactPersonNameField(): string {
    return "Имя"
  }

  contactPersonPhoneNumberField(): string {
    return "Номер телефона"
  }

  existObjectTitle(): string {
    return "Топливная компания"
  }

  legalEntityField(): string {
    return "Юр. лицо"
  }

  legalEntityIndividualTaxpayerNumberField(): string {
    return "ИНН"
  }

  legalEntityTaxRegistrationReasonCode(): string {
    return "КПП"
  }

  listTitle(): string {
    return "Топливные компании"
  }

  nameField(): string {
    return "Наименование"
  }

  newObjectTitle(): string {
    return "Новая топливная компания"
  }

  refuellingOrdersLink(): string {
    return "Заправки"
  }

  statusField(): string {
    return "Статус"
  }

  gpsCoordinatesField(): string {
    return "Координаты"
  }

  statusValueActive(): string {
    return "Активна"
  }

  statusValueBlocked(): string {
    return "Заблокирована"
  }

  usersLink(): string {
    return "Пользователи"
  }

  legalEntityNameField(): string {
    return "Название"
  }

  installationInstancesLink(): string {
    return "Устройства"
  }

  gasStationsLink(): string {
    return "АЗС"
  }

  creditLimitField(): string {
    return "Финансы"
  }

  creditLimitValueField(): string {
    return "Кредитный лимит"
  }

  creditLimitPeriodTypeField(): string {
    return "Расчетный период"
  }

  overallField(): string {
    return "Осталось"
  }

  debtField(): string {
    return "Лимит превышен на"
  }
}
