import FuelCompaniesForCurrentFuelCompanyBalancesRepository
  from "../../domain/repositories/FuelCompaniesForCurrentFuelCompanyBalancesRepository"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelCompaniesNetworkSource from "../../../../core/data/sources/network/FuelCompaniesNetworkSource"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesMapper from "../../../../core/data/mappers/FuelCompaniesMapper"

export default class DefaultFuelCompaniesForCurrentFuelCompanyBalancesRepository
  implements FuelCompaniesForCurrentFuelCompanyBalancesRepository {
  private readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource

  constructor(parameters: {
    readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource
  }) {
    this.fuelCompaniesNetworkSource = parameters.fuelCompaniesNetworkSource
  }

  async getFuelCompany(): Promise<GetObjectResult<FuelCompany>> {
    const mapper = new FuelCompaniesMapper()
    const result = await this.fuelCompaniesNetworkSource.getCurrentFuelCompany()

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompany: result.data.fuelCompany!,
            creditLimitPeriodTypeSelectOptions: []
          })
        }
      default:
        return result
    }
  }
}
