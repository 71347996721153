import React, { useLayoutEffect, useRef } from "react"
import styles from "./BalanceHeaderComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../admin/core/presentation/contexts/CoreThemeProviderContext"
import { Link } from "react-router-dom"
import SegmentControlComponent, {
  SegmentControlItem
} from "../../../../../admin/lib/segment-control/SegmentControlComponent"
import isPresent from "../../../../../admin/lib/isPresent"

interface HeaderAction {
  readonly name: string
  readonly url?: string | null
}

interface Props {
  readonly ownerName?: string
  readonly formattedBalanceValue?: string
  readonly subtitle?: string
  readonly action?: HeaderAction
  readonly needShowAction?: boolean
  readonly segmentControlItems: SegmentControlItem[]
  readonly selectedSegmentControlItem?: SegmentControlItem
  readonly onSelectSegmentControlItem: (segmentItem: SegmentControlItem) => void
  readonly onChangeHeaderHeight?: (height: number) => void
}

export default function BalanceHeaderComponent({
  ownerName = "",
  formattedBalanceValue = "",
  subtitle = "",
  action,
  needShowAction = true,
  segmentControlItems,
  selectedSegmentControlItem,
  onSelectSegmentControlItem,
  onChangeHeaderHeight = () => null
}: Props) {
  const coreTheme = useCoreThemeProvider()
  const rootRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const height = rootRef.current?.clientHeight
    if (isPresent(height)) {
      onChangeHeaderHeight(height)
    }
  }, [])

  return (
    <div className={styles.root} ref={rootRef}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <h1 className={`${styles.carrierName} ${coreTheme.headline5PrimaryClassName()}`}>
              {ownerName}
            </h1>
            <div className={coreTheme.headline6PrimaryClassName()}>
              {formattedBalanceValue}
            </div>
            {action?.url && needShowAction && (
              <Link
                to={action.url}
                className={coreTheme.body1LinkClassName()}
              >
                {action.name}
              </Link>
            )}
          </div>
          <div className={styles.subtitleContainer}>
            <h2 className={`${styles.subtitle} ${coreTheme.body1PrimaryClassName()}`}>
              {subtitle}
            </h2>
          </div>
          <div className={styles.segmentsContainer}>
            <SegmentControlComponent
              items={segmentControlItems}
              selectedItem={selectedSegmentControlItem}
              componentStyles={coreTheme.segmentControl1PrimaryStyle()}
              onSelectSegment={(item) => {
                onSelectSegmentControlItem(item)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
