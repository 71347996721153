import NetworkCreditLimit from "../entities/credit-limits/NetworkCreditLimit"
import CreditLimit from "../../domain/credit-limit/CreditLimit"
import { CreditLimitPeriodTypeSelectOption } from "../../domain/credit-limit/CreditLimitPeriodType"

export default class CreditLimitsMapper {
  mapNetworkToDomain({
    creditLimit,
    creditLimitPeriodTypeSelectOptions
  }: {
    readonly creditLimit: NetworkCreditLimit
    readonly creditLimitPeriodTypeSelectOptions: CreditLimitPeriodTypeSelectOption[]
  }): CreditLimit {
    return {
      id: creditLimit.id,
      value: creditLimit.value,
      formattedValue: creditLimit.formattedValue,
      periodType: creditLimit.periodType,
      periodTypeSelectionOption:
        creditLimitPeriodTypeSelectOptions.find((option) => option.id === creditLimit.periodType)
    }
  }

  mapDomainToNetwork({
    creditLimit
  }: {
    readonly creditLimit: CreditLimit
  }): NetworkCreditLimit {
    return {
      value: creditLimit.value,
      periodType: creditLimit.periodType
    }
  }
}
