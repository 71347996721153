import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import {
  GetCreditLimitPeriodTypesParameters
} from "../../domain/use-cases/GetCreditLimitPeriodTypesUseCase"
import CreditLimitPeriodTypesRepository
  from "../../domain/repositories/CreditLimitPeriodTypesRepository"
import CreditLimitPeriodTypesStaticSource from "../../../../core/data/sources/static/CreditLimitPeriodTypesStaticSource"
import { CreditLimitPeriodTypeSelectOption } from "../../../../core/domain/credit-limit/CreditLimitPeriodType"

export default class DefaultCreditLimitTypesRepository
  implements CreditLimitPeriodTypesRepository {
  private readonly creditLimitPeriodTypesStaticSource: CreditLimitPeriodTypesStaticSource

  constructor(parameters: {
    readonly creditLimitPeriodTypesStaticSource: CreditLimitPeriodTypesStaticSource
  }) {
    this.creditLimitPeriodTypesStaticSource = parameters.creditLimitPeriodTypesStaticSource
  }

  async getCreditLimitPeriodTypes({
    ids
  }: GetCreditLimitPeriodTypesParameters): Promise<
    GetObjectsResult<CreditLimitPeriodTypeSelectOption>
  > {
    const types = await this.creditLimitPeriodTypesStaticSource.getCreditLimitPeriodTypes({
      ids
    })

    return {
      type: "success",
      data: {
        objects: types,
        page: {
          hasMore: false
        }
      }
    }
  }
}
