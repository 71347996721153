import FuelCompanyBalancePresenter from "./FuelCompanyBalancePresenter"
import FuelCompanyBalancePage from "./FuelCompanyBalancePage"
import React from "react"
import useLocationContext from "../../../../../../admin/lib/presenter/useLocationContext"
import { Params, useLocation, useParams, useSearchParams } from "react-router-dom"
import isBlank from "../../../../../../admin/lib/isBlank"
import addSearchParam from "../../../../../../admin/lib/addSearchParam"
import FuelCompanyBalancesI18n from "../../../i18n/FuelCompanyBalancesI18n"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import { fuelCompanyIdUrlPart } from "../../../../../core/presentation/constants/urlParts"
import AppI18 from "../../../../../core/i18n/AppI18"

const TRANSACTIONS_TYPE_KEY = "transactions_type"

interface Props {
  readonly appI18n: AppI18
  readonly fuelCompanyBalancesI18n: FuelCompanyBalancesI18n
  readonly providePresenter: (parameters: {
    readonly fuelCompanyId: string
    readonly transactionsType?: BalanceTransactionType | null
  }) => FuelCompanyBalancePresenter
}

export default function FuelCompanyBalancePageRouteElement({
  providePresenter,
  fuelCompanyBalancesI18n,
  appI18n
}: Props) {
  const urlParams: Readonly<Params> = useParams()
  const fuelCompanyId: string = urlParams[fuelCompanyIdUrlPart]!
  const locationContext = useLocationContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const transactionsType = parseBalanceTransactionType(searchParams.get(TRANSACTIONS_TYPE_KEY))

  function addTransactionsTypeToSearchParams(transactionsType: string) {
    let newSearchParams: URLSearchParams = searchParams

    newSearchParams = addSearchParam({
      searchParams: newSearchParams,
      key: TRANSACTIONS_TYPE_KEY,
      value: transactionsType
    })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function parseBalanceTransactionType(string: string | null): BalanceTransactionType | undefined {
    if (isBlank(string)) {
      return undefined
    }

    return string as BalanceTransactionType
  }

  return (
    <FuelCompanyBalancePage
      appI18n={appI18n}
      fuelCompanyBalancesI18n={fuelCompanyBalancesI18n}
      key={locationContext.locationId}
      cacheTransactionsType={addTransactionsTypeToSearchParams}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            fuelCompanyId,
            transactionsType
          })
        })
      }}
    />
  )
}
