import React from "react"
import CurrentFuelCompanyBalanceView, {
  CurrentFuelCompanyBalanceHeaderViewState, CurrentFuelCompanyBalanceListItem, CurrentFuelCompanyBalanceListViewState
} from "./CurrentFuelCompanyBalanceView"
import CurrentFuelCompanyBalancePresenter from "./CurrentFuelCompanyBalancePresenter"
import CurrentFuelCompanyBalancesI18n from "../../../i18n/CurrentFuelCompanyBalancesI18n"
import HelmetComponent from "../../../../../../admin/core/presentation/components/helmet/HelmetComponent"
import BalanceHeaderComponent from "../../../../../core/presentation/components/balance-header/BalanceHeaderComponent"
import isPresent from "../../../../../../admin/lib/isPresent"
import isBlank from "../../../../../../admin/lib/isBlank"
import { SegmentControlItem } from "../../../../../../admin/lib/segment-control/SegmentControlComponent"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import TableComponent from "../../../../../../admin/features/objects/presentation/components/table/TableComponent"
import ExecutionError from "../../../../../../admin/core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../../admin/core/domain/exceptions/ApplicationException"
import AppUrlProvider from "../../../../../core/presentation/services/AppUrlProvider"
import { Navigate } from "react-router-dom"
import withRubbleSymbol from "../../../../../../admin/lib/withRubbleSymbol"
import AppI18 from "../../../../../core/i18n/AppI18"

interface Props {
  readonly appI18n: AppI18
  readonly currentFuelCompanyBalancesI18n: CurrentFuelCompanyBalancesI18n
  readonly providePresenter: () => CurrentFuelCompanyBalancePresenter
  readonly cacheTransactionsType: (transactionsType: string) => void
}

interface State {
  readonly currentFuelCompanyBalanceListViewState?: CurrentFuelCompanyBalanceListViewState
  readonly currentFuelCompanyBalanceHeaderViewState?: CurrentFuelCompanyBalanceHeaderViewState
  readonly paddingTop?: number
}

export default class CurrentFuelCompanyBalancePage
  extends React.Component<Props, State>
  implements CurrentFuelCompanyBalanceView {

  private readonly appI18n: AppI18
  private readonly presenter: CurrentFuelCompanyBalancePresenter
  private readonly currentFuelCompanyBalancesI18n: CurrentFuelCompanyBalancesI18n

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.appI18n = props.appI18n
    this.presenter = props.providePresenter()
    this.currentFuelCompanyBalancesI18n = props.currentFuelCompanyBalancesI18n
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showCurrentFuelCompanyBalanceListViewState(
    currentFuelCompanyBalanceListViewState: CurrentFuelCompanyBalanceListViewState
  ): void {
    this.setState({
      currentFuelCompanyBalanceListViewState
    })
  }

  showCurrentFuelCompanyBalanceHeaderViewState(
    currentFuelCompanyBalanceHeaderViewState: CurrentFuelCompanyBalanceHeaderViewState
  ): void {
    this.setState({
      currentFuelCompanyBalanceHeaderViewState
    })
  }

  cacheSelectedTransactionsType(transactionsType: string): void {
    this.props.cacheTransactionsType(transactionsType)
  }

  render() {
    const {
      currentFuelCompanyBalanceListViewState,
      currentFuelCompanyBalanceHeaderViewState,
      paddingTop
    } = this.state

    const appUrlProvider = new AppUrlProvider()
    if (currentFuelCompanyBalanceListViewState?.type === "forbidden") {
      return <Navigate to={appUrlProvider.buildForbiddenUrl()} replace={true} />
    }

    if (
      isBlank(currentFuelCompanyBalanceListViewState) ||
      isBlank(currentFuelCompanyBalanceHeaderViewState)
    ) return <></>

    const fuelCompanyBalancesTextProvider =
      this.currentFuelCompanyBalancesI18n.getTextProvider()

    const fuelCompany = (() => {
      switch (currentFuelCompanyBalanceHeaderViewState.type) {
        case "loaded": {
          return currentFuelCompanyBalanceHeaderViewState.fuelCompany
        }
        default:
          return null
      }
    })()

    const {
      table
    } = currentFuelCompanyBalanceListViewState

    const pageTitle = isPresent(fuelCompany) ?
      fuelCompanyBalancesTextProvider.listTitle({
        fuelCompanyName: fuelCompany.name,
        fuelCompanyBalance: fuelCompany.balance?.formattedValue
      }) : fuelCompanyBalancesTextProvider.listTitle()

    const segmentControlItems: SegmentControlItem[] = [
      {
        id: BalanceTransactionType.REPLENISHMENT,
        text: fuelCompanyBalancesTextProvider.replenishmentsSegment()
      },
      {
        id: BalanceTransactionType.WRITE_OFF,
        text: fuelCompanyBalancesTextProvider.writeOffsSegment()
      }
    ]

    const selectedSegmentControlItems = segmentControlItems
      .find((segmentControlItem) => segmentControlItem.id === currentFuelCompanyBalanceHeaderViewState.transactionType)

    const items: CurrentFuelCompanyBalanceListItem[] = (() => {
      switch (currentFuelCompanyBalanceListViewState.type) {
        case "loaded":
        case "next_page_loading":
        case "next_page_loading_error":
        case "next_page_loading_failure": {
          return currentFuelCompanyBalanceListViewState.items
        }
        default:
          return []
      }
    })()

    const transactionsLoadingError: ExecutionError | undefined = (() => {
      switch (currentFuelCompanyBalanceListViewState.type) {
        case "loading_error":
        case "next_page_loading_error": {
          return currentFuelCompanyBalanceListViewState.error
        }
        default:
          return undefined
      }
    })()

    const transactionsLoadingException: ApplicationException | undefined = (() => {
      switch (currentFuelCompanyBalanceListViewState.type) {
        case "loading_failure":
        case "next_page_loading_failure": {
          return currentFuelCompanyBalanceListViewState.exception
        }
        default:
          return undefined
      }
    })()

    const transactionsIsLoading = currentFuelCompanyBalanceListViewState.type === "initializing" ||
      currentFuelCompanyBalanceListViewState.type === "loading" ||
      currentFuelCompanyBalanceListViewState.type === "next_page_loading"

    return (
      <>
        <HelmetComponent
          title={pageTitle}
        />
        {currentFuelCompanyBalanceHeaderViewState.type !== "initializing" && (
          <BalanceHeaderComponent
            ownerName={fuelCompany?.name ?? pageTitle}
            formattedBalanceValue={withRubbleSymbol(fuelCompany?.balance?.formattedValue)}
            onChangeHeaderHeight={(height) => {
              this.setState({
                paddingTop: height
              })
            }}
            subtitle={(() => {
              if (isPresent(fuelCompany?.formattedOverall)) {
                return this.appI18n.getTextProvider().creditLimitOverall({
                  formattedCreditLimit: fuelCompany?.creditLimit?.formattedValue,
                  formattedValue: fuelCompany?.formattedOverall
                })
              }

              if (isPresent(fuelCompany?.formattedDebt)) {
                return this.appI18n.getTextProvider().creditLimitDebt({
                  formattedValue: fuelCompany?.formattedDebt
                })
              }

              return ""
            })()}
            needShowAction={table.getCanCreateNewObject() === true}
            action={{
              url: fuelCompany && appUrlProvider.buildFuelCompanyBalanceChangeDocumentUrl({
                fuelCompanyId: fuelCompany.id!
              }),
              name: fuelCompanyBalancesTextProvider.writeOffBalance()
            }}
            segmentControlItems={segmentControlItems}
            selectedSegmentControlItem={selectedSegmentControlItems}
            onSelectSegmentControlItem={(segmentControlItem) => {
              this.presenter.onSegmentSelected(segmentControlItem.id)
            }}
          />
        )}
        <TableComponent
          paddingTop={paddingTop}
          table={table}
          objects={items}
          isLoading={transactionsIsLoading}
          exception={transactionsLoadingException}
          error={transactionsLoadingError}
          onNextPageRequested={this.presenter.onNextPageRequested}
          onRetryLoadClicked={this.presenter.onRetryLoadClicked}
        />
      </>
    )
  }
}
