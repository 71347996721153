import Carrier from "../../../../core/domain/carriers/Carrier"
import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import CarriersI18 from "../../i18n/CarriersI18"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import formatPhoneNumber from "../../../../../admin/lib/formatPhone"

export default class CarrierInfoProvider implements InfoProvider<Carrier> {
  private readonly carriersI18: CarriersI18

  constructor(parameters: {
    readonly carriersI18: CarriersI18
  }) {
    this.carriersI18 = parameters.carriersI18
  }

  getEntity(): string {
    return Entity.CARRIERS
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: Carrier
  }): string {
    if (isBlank(object)) return this.carriersI18.getTextProvider().existObjectTitle()

    const { name } = object

    return isPresent(name) ? name : this.carriersI18.getTextProvider().existObjectTitle()
  }

  getObjectTitle({
    object
  }: {
    readonly object?: Carrier
  }): string {
    if (isBlank(object)) return this.carriersI18.getTextProvider().existObjectTitle()

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.carriersI18.getTextProvider().existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: Carrier
  }): InfoRow[] {
    const carriersTextProvider = this.carriersI18.getTextProvider()

    return [
      new StringInfoRow({
        name: "balance",
        title: carriersTextProvider.balanceField(),
        value: (() => {
          const { balance } = object
          if (isBlank(balance)) return null

          const { formattedValue } = balance
          return formattedValue && withRubbleSymbol(formattedValue)
        })()
      }),
      new StringInfoRow({
        name: "legalEntity",
        title: carriersTextProvider.legalEntityField(),
        value: object.legalEntity?.name
      }),
      new StringInfoRow({
        name: "individualTaxpayerNumber",
        title: carriersTextProvider.legalEntityIndividualTaxpayerNumberField(),
        value: object.legalEntity?.individualTaxpayerNumber
      }),
      new StringInfoRow({
        name: "taxRegistrationReasonCode",
        title: carriersTextProvider.legalEntityTaxRegistrationReasonCode(),
        value: object.legalEntity?.taxRegistrationReasonCode
      }),
      new StringInfoRow({
        name: "contactPerson",
        title: carriersTextProvider.contactPersonField(),
        value: object.contactPerson?.name,
        additionalValue: (() => {
          const { contactPerson } = object
          if (isBlank(contactPerson)) return null

          const { phoneNumber, email } = contactPerson
          return filterNotEmpty([
            formatPhoneNumber(phoneNumber),
            email
          ])
            .join(", ")
        })()
      }),
      new StringInfoRow({
        name: "blocked",
        title: carriersTextProvider.statusField(),
        value: (() => {
          const { blocked = false } = object

          return (blocked ?? false) ?
            carriersTextProvider.statusValueBlocked() :
            carriersTextProvider.statusValueActive()
        })()
      })
    ]
  }

  getLinks({ object }: {
    readonly object: Carrier
  }): LinkInfo[] {
    const urlProvider = new AppUrlProvider()
    const carriersTextProvider = this.carriersI18.getTextProvider()

    return [
      {
        id: "refuellingOrders",
        entity: Entity.REFUELLING_ORDERS,
        title: carriersTextProvider.refuellingOrdersLink(),
        url: urlProvider.buildRefuellingOrdersUrl({
          carrierId: object.id
        })
      },
      {
        id: "drivers",
        entity: Entity.DRIVERS,
        title: carriersTextProvider.driversLink(),
        url: urlProvider.buildDriversUrl({
          carrierId: object.id
        })
      },
      {
        id: "transports",
        entity: Entity.TRANSPORTS,
        title: carriersTextProvider.transportsLink(),
        url: urlProvider.buildTransportsUrl({
          carrierId: object.id
        })
      },
      {
        id: "trips",
        entity: Entity.TRIPS,
        title: carriersTextProvider.tripsLink(),
        url: urlProvider.buildTripsUrl({
          carrierId: object.id
        })
      },
      {
        id: "carrier_balance",
        entity: Entity.CARRIER_BALANCE_REPLENISHMENTS,
        title: carriersTextProvider.balanceLink(),
        url: urlProvider.buildCarrierBalanceUrl({
          carrierId: object.id!
        })
      },
      {
        id: "users",
        entity: Entity.USERS,
        title: carriersTextProvider.usersLink(),
        url: urlProvider.buildCarrierUsersUrl({
          carrierId: object.id
        })
      }
    ]
  }

  getEditUrl({
    object
  }: {
    readonly object: Carrier
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditCarrierUrl({
      carrierId: object.id!
    })
  }
}
