import SelectOption from "../../../../admin/lib/picker/SelectOption"

enum CreditLimitPeriodType {
  ONE_MONTH = "oneMonth",
  HALF_OF_MONTH = "halfOfMonth",
  THIRD_OF_MONTH = "thirdOfMonth",
  ONE_WEEK = "oneWeek"
}

export default CreditLimitPeriodType

export interface CreditLimitPeriodTypeSelectOption extends SelectOption { }
