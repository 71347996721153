import CarriersMapper from "./CarriersMapper"
import FuelCompaniesMapper from "./FuelCompaniesMapper"
import FuelsMapper from "./FuelsMapper"
import GasStationsMapper from "./GasStationsMapper"
import { PricingTypeSelectOption } from "../../domain/commercial-terms/PricingType"
import NetworkFuelCompanyCommercialTerm
  from "../entities/fuel-company-commercial-terms/NetworkFuelCompanyCommercialTerm"
import FuelCompanyCommercialTerm from "../../domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"

export default class FuelCompanyCommercialTermsMapper {
  mapNetworkToDomain({
    fuelCompanyCommercialTerm,
    pricingTypeSelectOptions
  }: {
    readonly fuelCompanyCommercialTerm: NetworkFuelCompanyCommercialTerm
    readonly pricingTypeSelectOptions: PricingTypeSelectOption[]
  }): FuelCompanyCommercialTerm {
    const {
      carrier,
      fuelCompany,
      gasStations,
      fuel
    } = fuelCompanyCommercialTerm

    const gasStationsMapper = new GasStationsMapper()

    return {
      id: fuelCompanyCommercialTerm.id,
      carrierId: fuelCompanyCommercialTerm.carrierId,
      carrier: carrier && new CarriersMapper().mapNetworkToDomain({ carrier }),
      fuelCompanyId: fuelCompanyCommercialTerm.fuelCompanyId,
      fuelCompany: fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({
        fuelCompany,
        creditLimitPeriodTypeSelectOptions: []
      }),
      fuelId: fuelCompanyCommercialTerm.fuelId,
      fuel: fuel && new FuelsMapper().mapNetworkToDomain({ fuel }),
      gasStationIds: fuelCompanyCommercialTerm.gasStationIds,
      gasStations: gasStations && gasStations.map((gasStation) => {
        return gasStationsMapper.mapNetworkToDomain({ gasStation })
      }),
      beginDate: fuelCompanyCommercialTerm.beginDate,
      endDate: fuelCompanyCommercialTerm.endDate,
      pricingType: fuelCompanyCommercialTerm.pricingType,
      pricingTypeSelectOption: pricingTypeSelectOptions.find((option) => {
        return option.id === fuelCompanyCommercialTerm.pricingType
      }),
      price: fuelCompanyCommercialTerm.price,
      formattedPrice: fuelCompanyCommercialTerm.formattedPrice,
      discountPercentage: fuelCompanyCommercialTerm.discountPercentage,
      formattedDiscountPercentage: fuelCompanyCommercialTerm.formattedDiscountPercentage,
      documentUrl: fuelCompanyCommercialTerm.documentUrl
    }
  }

  mapDomainToNetwork({
    fuelCompanyCommercialTerm
  }: {
    readonly fuelCompanyCommercialTerm: FuelCompanyCommercialTerm
  }): NetworkFuelCompanyCommercialTerm {
    return {
      carrierId: fuelCompanyCommercialTerm.carrierId,
      fuelCompanyId: fuelCompanyCommercialTerm.fuelCompanyId,
      fuelId: fuelCompanyCommercialTerm.fuelId,
      gasStationIds: fuelCompanyCommercialTerm.gasStationIds,
      beginDate: fuelCompanyCommercialTerm.beginDate,
      endDate: fuelCompanyCommercialTerm.endDate,
      pricingType: fuelCompanyCommercialTerm.pricingType,
      price: fuelCompanyCommercialTerm.price,
      discountPercentage: fuelCompanyCommercialTerm.discountPercentage,
      documentUrl: fuelCompanyCommercialTerm.documentUrl
    }
  }
}
