import DiModule from "../../../../admin/lib/di/DiModule"
import AppNetworkDiModule from "./AppNetworkDiModule"
import MenusNetworkSource from "../../../features/root/data/sources/menus/MenusNetworkSource"
import CarriersNetworkSource from "../../data/sources/network/CarriersNetworkSource"
import FuelCompaniesNetworkSource from "../../data/sources/network/FuelCompaniesNetworkSource"
import GasStationsNetworkSource from "../../data/sources/network/GasStationsNetworkSource"
import FuelsNetworkSource from "../../data/sources/network/FuelsNetworkSource"
import CarrierCommercialTermsNetworkSource from "../../data/sources/network/CarrierCommercialTermsNetworkSource"
import DriversNetworkSource from "../../data/sources/network/DriversNetworkSource"
import PricingTypesStaticSource from "../../data/sources/static/PricingTypesStaticSource"
import AppI18nDiModule from "./AppI18nDiModule"
import FuelCompanyCommercialTermsNetworkSource from "../../data/sources/network/FuelCompanyCommercialTermsNetworkSource"
import InstallationInstancesNetworkSource from "../../data/sources/network/InstallationInstancesNetworkSource"
import TransportsNetworkSource from "../../data/sources/network/TransportsNetworkSource"
import FuelPricesNetworkSource from "../../data/sources/network/FuelPricesNetworkSource"
import TripsNetworkSource from "../../data/sources/network/TripsNetworkSource"
import TripStatusesStaticSource from "../../data/sources/static/TripStatusesStaticSource"
import ChangingLogItemsNetworkSource from "../../data/sources/network/ChangingLogItemsNetworkSource"
import CarrierBalanceTransactionsNetworkSource from "../../data/sources/network/CarrierBalanceTransactionsNetworkSource"
import RefuellingOrdersNetworkSource from "../../data/sources/network/RefuellingOrdersNetworkSource"
import RefuellingOrderStatusesStaticSource from "../../data/sources/static/RefuellingOrderStatusesStaticSource"
import FuelCompanyBalanceTransactionsNetworkSource
  from "../../data/sources/network/FuelCompanyBalanceTransactionsNetworkSource"
import ChangingTypesStaticSource from "../../data/sources/static/ChangingTypesStaticSource"
import UsersNetworkSource from "../../data/sources/network/UsersNetworkSource"
import CarrierUsersNetworkSource from "../../data/sources/network/CarrierUsersNetworkSource"
import FuelCompanyUsersNetworkSource from "../../data/sources/network/FuelCompanyUsersNetworkSource"
import CurrentCarrierBalanceTransactionsNetworkSource
  from "../../data/sources/network/CurrentCarrierBalanceTransactionsNetworkSource"
import CurrentFuelCompanyBalanceTransactionsNetworkSource
  from "../../data/sources/network/CurrentFuelCompanyBalanceTransactionsNetworkSource"
import AuthorizationErrorNetworkSource
  from "../../../features/root/data/sources/authorization-error/AuthorizationErrorNetworkSource"
import TripLimitTypesNetworkSource from "../../data/sources/network/TripLimitTypesNetworkSource"
import RefuellingOrdersReportsNetworkSource from "../../data/sources/network/RefuellingOrdersReportsNetworkSource"
import CarrierReconciliationReportsNetworkSource
  from "../../data/sources/network/CarrierReconciliationReportsNetworkSource"
import FuelCompanyReconciliationReportsNetworkSource
  from "../../data/sources/network/FuelCompanyReconciliationReportsNetworkSource"
import FuelCompanyBalanceChangeDocumentsNetworkSource
  from "../../data/sources/network/FuelCompanyBalanceChangeDocumentsNetworkSource"
import CurrentFuelCompanyBalanceChangeDocumentsNetworkSource
  from "../../data/sources/network/CurrentFuelCompanyBalanceChangeDocumentsNetworkSource"
import CarrierBalanceChangeDocumentsNetworkSource
  from "../../data/sources/network/CarrierBalanceChangeDocumentsNetworkSource"
import CurrentCarrierBalanceChangeDocumentsNetworkSource
  from "../../data/sources/network/CurrentCarrierBalanceChangeDocumentsNetworkSource"
import GasStationBreaksNetworkSource from "../../data/sources/network/GasStationBreaksNetworkSource"
import InstallationInstanceTypesStaticSource from "../../data/sources/static/InstallationInstanceTypesStaticSource"
import CreditLimitPeriodTypesStaticSource from "../../data/sources/static/CreditLimitPeriodTypesStaticSource"

export default interface AppDataDiModule {
  provideMenusNetworkSource(): MenusNetworkSource

  provideAuthorizationErrorNetworkSource(): AuthorizationErrorNetworkSource

  provideCarriersNetworkSource(): CarriersNetworkSource

  provideFuelCompaniesNetworkSource(): FuelCompaniesNetworkSource

  provideGasStationsNetworkSource(): GasStationsNetworkSource

  provideTransportsNetworkSource(): TransportsNetworkSource

  provideFuelsNetworkSource(): FuelsNetworkSource

  provideCarrierCommercialTermsNetworkSource(): CarrierCommercialTermsNetworkSource

  providePricingTypesStaticSource(): PricingTypesStaticSource

  provideRefuellingOrderStatusesStaticSource(): RefuellingOrderStatusesStaticSource

  provideFuelCompanyCommercialTermsNetworkSource(): FuelCompanyCommercialTermsNetworkSource

  provideInstallationInstancesNetworkSource(): InstallationInstancesNetworkSource

  provideFuelPricesNetworkSource(): FuelPricesNetworkSource

  provideDriversNetworkSource(): DriversNetworkSource

  provideUsersNetworkSource(): UsersNetworkSource

  provideCarrierUsersNetworkSource(): CarrierUsersNetworkSource

  provideFuelCompanyUsersNetworkSource(): FuelCompanyUsersNetworkSource

  provideTripsNetworkSource(): TripsNetworkSource

  provideTripStatusesStaticSource(): TripStatusesStaticSource

  provideTripLimitTypesNetworkSource(): TripLimitTypesNetworkSource

  provideChangingLogItemsNetworkSource(): ChangingLogItemsNetworkSource

  provideRefuellingOrdersNetworkSource(): RefuellingOrdersNetworkSource

  provideRefuellingOrdersReportsNetworkSource(): RefuellingOrdersReportsNetworkSource

  provideCarrierReconciliationReportsNetworkSource(): CarrierReconciliationReportsNetworkSource

  provideFuelCompanyReconciliationReportsNetworkSource(): FuelCompanyReconciliationReportsNetworkSource

  provideCarrierBalanceTransactionsNetworkSource(): CarrierBalanceTransactionsNetworkSource

  provideCarrierBalanceChangeDocumentsNetworkSource(): CarrierBalanceChangeDocumentsNetworkSource

  provideCurrentCarrierBalanceTransactionsNetworkSource(): CurrentCarrierBalanceTransactionsNetworkSource

  provideCurrentCarrierBalanceChangeDocumentsNetworkSource(): CurrentCarrierBalanceChangeDocumentsNetworkSource

  provideFuelCompanyBalanceTransactionsNetworkSource(): FuelCompanyBalanceTransactionsNetworkSource

  provideFuelCompanyBalanceChangeDocumentsNetworkSource(): FuelCompanyBalanceChangeDocumentsNetworkSource

  provideCurrentFuelCompanyBalanceTransactionsNetworkSource(): CurrentFuelCompanyBalanceTransactionsNetworkSource

  provideCurrentFuelCompanyBalanceChangeDocumentsNetworkSource(): CurrentFuelCompanyBalanceChangeDocumentsNetworkSource

  provideChangingTypesStaticSource(): ChangingTypesStaticSource

  provideGasStationBreaksNetworkSource(): GasStationBreaksNetworkSource

  provideInstallationInstanceTypesStaticSource(): InstallationInstanceTypesStaticSource

  provideCreditLimitPeriodTypesStaticSource(): CreditLimitPeriodTypesStaticSource
}

export class DefaultAppDataDiModule extends DiModule implements AppDataDiModule {
  private readonly appNetworkDiModule: AppNetworkDiModule
  private readonly appI18nDiModule: AppI18nDiModule

  constructor(parameters: {
    readonly appNetworkDiModule: AppNetworkDiModule
    readonly appI18nDiModule: AppI18nDiModule
  }) {
    super()

    this.appNetworkDiModule = parameters.appNetworkDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
  }

  provideCarrierBalanceChangeDocumentsNetworkSource(): CarrierBalanceChangeDocumentsNetworkSource {
    return this.single(
      CarrierBalanceChangeDocumentsNetworkSource.name,
      () => new CarrierBalanceChangeDocumentsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCurrentCarrierBalanceChangeDocumentsNetworkSource(): CurrentCarrierBalanceChangeDocumentsNetworkSource {
    return this.single(
      CurrentCarrierBalanceChangeDocumentsNetworkSource.name,
      () => new CurrentCarrierBalanceChangeDocumentsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideMenusNetworkSource(): MenusNetworkSource {
    return this.single(
      MenusNetworkSource.name,
      () => new MenusNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideAuthorizationErrorNetworkSource(): AuthorizationErrorNetworkSource {
    return this.single(
      AuthorizationErrorNetworkSource.name,
      () => new AuthorizationErrorNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCarriersNetworkSource(): CarriersNetworkSource {
    return this.single(
      CarriersNetworkSource.name,
      () => new CarriersNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideFuelCompaniesNetworkSource(): FuelCompaniesNetworkSource {
    return this.single(
      FuelCompaniesNetworkSource.name,
      () => new FuelCompaniesNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideGasStationsNetworkSource(): GasStationsNetworkSource {
    return this.single(
      GasStationsNetworkSource.name,
      () => new GasStationsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideFuelsNetworkSource(): FuelsNetworkSource {
    return this.single(
      FuelsNetworkSource.name,
      () => new FuelsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCarrierCommercialTermsNetworkSource(): CarrierCommercialTermsNetworkSource {
    return this.single(
      CarrierCommercialTermsNetworkSource.name,
      () => new CarrierCommercialTermsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  providePricingTypesStaticSource(): PricingTypesStaticSource {
    return this.single(
      PricingTypesStaticSource.name,
      () => new PricingTypesStaticSource({
        appI18n: this.appI18nDiModule.provideAppI18n()
      })
    )
  }

  provideRefuellingOrderStatusesStaticSource(): RefuellingOrderStatusesStaticSource {
    return this.single(
      RefuellingOrderStatusesStaticSource.name,
      () => new RefuellingOrderStatusesStaticSource({
        appI18n: this.appI18nDiModule.provideAppI18n()
      })
    )
  }

  provideFuelCompanyCommercialTermsNetworkSource(): FuelCompanyCommercialTermsNetworkSource {
    return this.single(
      FuelCompanyCommercialTermsNetworkSource.name,
      () => new FuelCompanyCommercialTermsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideInstallationInstancesNetworkSource(): InstallationInstancesNetworkSource {
    return this.single(
      InstallationInstancesNetworkSource.name,
      () => new InstallationInstancesNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideTransportsNetworkSource(): TransportsNetworkSource {
    return this.single(
      TransportsNetworkSource.name,
      () => new TransportsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideFuelPricesNetworkSource(): FuelPricesNetworkSource {
    return this.single(
      FuelPricesNetworkSource.name,
      () => new FuelPricesNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideDriversNetworkSource(): DriversNetworkSource {
    return this.single(
      DriversNetworkSource.name,
      () => new DriversNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideUsersNetworkSource(): UsersNetworkSource {
    return this.single(
      UsersNetworkSource.name,
      () => new UsersNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCarrierUsersNetworkSource(): CarrierUsersNetworkSource {
    return this.single(
      CarrierUsersNetworkSource.name,
      () => new CarrierUsersNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideFuelCompanyUsersNetworkSource(): FuelCompanyUsersNetworkSource {
    return this.single(
      FuelCompanyUsersNetworkSource.name,
      () => new FuelCompanyUsersNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideTripsNetworkSource(): TripsNetworkSource {
    return this.single(
      TripsNetworkSource.name,
      () => new TripsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideTripStatusesStaticSource(): TripStatusesStaticSource {
    return this.single(
      TripStatusesStaticSource.name,
      () => new TripStatusesStaticSource({
        appI18n: this.appI18nDiModule.provideAppI18n()
      })
    )
  }

  provideTripLimitTypesNetworkSource(): TripLimitTypesNetworkSource {
    return this.single(
      TripLimitTypesNetworkSource.name,
      () => new TripLimitTypesNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideChangingLogItemsNetworkSource(): ChangingLogItemsNetworkSource {
    return this.single(
      ChangingLogItemsNetworkSource.name,
      () => new ChangingLogItemsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCarrierBalanceTransactionsNetworkSource(): CarrierBalanceTransactionsNetworkSource {
    return this.single(
      CarrierBalanceTransactionsNetworkSource.name,
      () => new CarrierBalanceTransactionsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCurrentCarrierBalanceTransactionsNetworkSource(): CurrentCarrierBalanceTransactionsNetworkSource {
    return this.single(
      CurrentCarrierBalanceTransactionsNetworkSource.name,
      () => new CurrentCarrierBalanceTransactionsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideRefuellingOrdersNetworkSource(): RefuellingOrdersNetworkSource {
    return this.single(
      RefuellingOrdersNetworkSource.name,
      () => new RefuellingOrdersNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideRefuellingOrdersReportsNetworkSource(): RefuellingOrdersReportsNetworkSource {
    return this.single(
      RefuellingOrdersReportsNetworkSource.name,
      () => new RefuellingOrdersReportsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCarrierReconciliationReportsNetworkSource(): CarrierReconciliationReportsNetworkSource {
    return this.single(
      CarrierReconciliationReportsNetworkSource.name,
      () => new CarrierReconciliationReportsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideFuelCompanyReconciliationReportsNetworkSource(): FuelCompanyReconciliationReportsNetworkSource {
    return this.single(
      FuelCompanyReconciliationReportsNetworkSource.name,
      () => new FuelCompanyReconciliationReportsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideFuelCompanyBalanceTransactionsNetworkSource(): FuelCompanyBalanceTransactionsNetworkSource {
    return this.single(
      FuelCompanyBalanceTransactionsNetworkSource.name,
      () => new FuelCompanyBalanceTransactionsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideFuelCompanyBalanceChangeDocumentsNetworkSource(): FuelCompanyBalanceChangeDocumentsNetworkSource {
    return this.single(
      FuelCompanyBalanceChangeDocumentsNetworkSource.name,
      () => new FuelCompanyBalanceChangeDocumentsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCurrentFuelCompanyBalanceTransactionsNetworkSource(): CurrentFuelCompanyBalanceTransactionsNetworkSource {
    return this.single(
      CurrentFuelCompanyBalanceTransactionsNetworkSource.name,
      () => new CurrentFuelCompanyBalanceTransactionsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideCurrentFuelCompanyBalanceChangeDocumentsNetworkSource():
    CurrentFuelCompanyBalanceChangeDocumentsNetworkSource {
    return this.single(
      CurrentFuelCompanyBalanceChangeDocumentsNetworkSource.name,
      () => new CurrentFuelCompanyBalanceChangeDocumentsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideChangingTypesStaticSource(): ChangingTypesStaticSource {
    return this.single(
      ChangingTypesStaticSource.name,
      () => new ChangingTypesStaticSource({
        appI18n: this.appI18nDiModule.provideAppI18n()
      })
    )
  }

  provideGasStationBreaksNetworkSource(): GasStationBreaksNetworkSource {
    return this.single(
      GasStationBreaksNetworkSource.name,
      () => new GasStationBreaksNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideInstallationInstanceTypesStaticSource(): InstallationInstanceTypesStaticSource {
    return this.single(
      InstallationInstanceTypesStaticSource.name,
      () => new InstallationInstanceTypesStaticSource({
        appI18n: this.appI18nDiModule.provideAppI18n()
      })
    )
  }

  provideCreditLimitPeriodTypesStaticSource(): CreditLimitPeriodTypesStaticSource {
    return this.single(
      CreditLimitPeriodTypesStaticSource.name,
      () => new CreditLimitPeriodTypesStaticSource({
        appI18n: this.appI18nDiModule.provideAppI18n()
      })
    )
  }
}
