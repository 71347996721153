import React, { ReactElement } from "react"
import CoreTheme from "../../admin/core/presentation/theme/CoreTheme"

import buttons from "./components/buttons.module.scss"
import labels from "./components/labels.module.scss"
import headlines from "./components/headlines.module.scss"
import bodies from "./components/bodies.module.scss"
import inputs from "./components/inputs.module.scss"
import menus from "./components/menus.module.scss"
import tables from "./components/tables.module.scss"
import objects from "./components/objects.module.scss"
import titles from "./components/titles.module.scss"
import checkboxes from "./components/checkboxes.module.scss"
import chips from "./components/chips.module.scss"
import dropdowns from "./components/dropdowns.module.scss"
import calendars from "./components/calendars.module.scss"
import segments from "./components/segments.module.scss"
import info from "./components/info.module.scss"

import { ReactComponent as IcProfileDefault } from "../resources/assets/icons/icProfileDefault.svg"
import { ReactComponent as IcSettingsDefault } from "../resources/assets/icons/icSettingsDefault.svg"
import { ReactComponent as IcLogOutDefault } from "../resources/assets/icons/icLogOutDefault.svg"
import { ReactComponent as IcSearchDefault } from "../resources/assets/icons/icSearchDefault.svg"
import { ReactComponent as IcArrowsUpDown } from "../resources/assets/icons/icArrowsUpDownOutlinedDefault.svg"
import { ReactComponent as IcArrowDown } from "../resources/assets/icons/icArrowDownOutlinedActive.svg"
import { ReactComponent as IcArrowUp } from "../resources/assets/icons/icArrowUpOutlinedActive.svg"
import { ReactComponent as IcLoadingIndicator } from "../resources/assets/icons/icLoadingIndicator.svg"
import { ReactComponent as IcExpandMore } from "../resources/assets/icons/icExpandMore.svg"
import { ReactComponent as IcExpandMoreActive } from "../resources/assets/icons/icExpandMoreActive.svg"
import { ReactComponent as IcExpandMoreDisabled } from "../resources/assets/icons/icExpandMoreDisabled.svg"
import { ReactComponent as IcCalendarDefault } from "../resources/assets/icons/icCalendarDefault.svg"
import { ReactComponent as IcCalendarActive } from "../resources/assets/icons/icCalendarActive.svg"
import { ReactComponent as IcArrowRightFilled } from "../resources/assets/icons/icArrowRightFilled.svg"
import { ReactComponent as IcArrowLeftFilled } from "../resources/assets/icons/icArrowLeftFilled.svg"

import PickerComponentStyles from "../../admin/lib/picker/PickerComponentStyles"
import { DatePickerComponentStyle } from "../../admin/lib/date-picker/date-picker/DatePickerComponent"
import ButtonComponentStyles from "../../admin/lib/button/ButtonComponentStyles"
import TextInputComponentStyle from "../../admin/lib/text-input/TextInputComponentStyles"
import SegmentControlComponentStyles from "../../admin/lib/segment-control/SegmentControlComponentStyles"

export default class AppCoreTheme implements CoreTheme {

  icProfileComponent(): ReactElement {
    return (
      <IcProfileDefault />
    )
  }

  icSettingsComponent(): ReactElement {
    return (
      <IcSettingsDefault />
    )
  }

  icLogoutComponent(): ReactElement {
    return (
      <IcLogOutDefault />
    )
  }

  icSearchComponent(): ReactElement {
    return (
      <div className={inputs.searchIcon}>
        <IcSearchDefault />
      </div>
    )
  }

  icArrowsUpDownOutlinedDefaultComponent(): ReactElement {
    return (
      <IcArrowsUpDown />
    )
  }

  icArrowUpOutlinedActiveComponent(): ReactElement {
    return (
      <IcArrowUp />
    )
  }

  icArrowDownOutlinedActiveComponent(): ReactElement {
    return (
      <IcArrowDown />
    )
  }

  icLoadingIndicatorComponent(): ReactElement {
    return (
      <IcLoadingIndicator />
    )
  }

  icExpandMoreActiveComponent(): React.ReactElement {
    return (
      <IcExpandMoreActive />
    )
  }

  icExpandMoreDisabledComponent(): React.ReactElement {
    return (
      <IcExpandMoreDisabled />
    )
  }

  icExpandMoreComponent(): React.ReactElement {
    return (
      <IcExpandMore />
    )
  }

  icCalendarDefaultComponent(): ReactElement {
    return (
      <div className={inputs.calendarIcon}>
        <IcCalendarDefault />
      </div>
    )
  }

  icCalendarActiveComponent(): ReactElement {
    return (
      <div className={inputs.calendarIcon}>
        <IcCalendarActive />
      </div>
    )
  }

  icArrowRightFilledComponent(): ReactElement {
    return (
      <IcArrowRightFilled />
    )
  }

  icArrowLeftFilledComponent(): ReactElement {
    return (
      <IcArrowLeftFilled />
    )
  }

  filterIconClassName(): string {
    return buttons.filterIcon
  }

  clearIconClassName(): string {
    return buttons.clearIcon
  }

  buttonFilled1PrimaryStyle(): ButtonComponentStyles {
    return {
      classes: {
        default: `${buttons.buttonFilled1} ${buttons.primary}`,
        disabled: `${buttons.buttonFilled1} ${buttons.primary} ${buttons.disabled}`
      }
    }
  }

  buttonFilled1SecondaryStyle(): ButtonComponentStyles {
    return {
      classes: {
        default: `${buttons.buttonFilled1} ${buttons.secondary}`,
        disabled: `${buttons.buttonFilled1} ${buttons.secondary} ${buttons.disabled}`
      }
    }
  }

  buttonFilled1ClearStyle(): ButtonComponentStyles {
    return {
      classes: {
        default: `${buttons.buttonFilled1} ${buttons.clear}`,
        disabled: `${buttons.buttonFilled1} ${buttons.clear} ${buttons.disabled}`
      }
    }
  }

  buttonOutlined1PrimaryStyle(): ButtonComponentStyles {
    return {
      classes: {
        default: `${buttons.buttonOutlined1} ${buttons.primary}`,
        selected: `${buttons.buttonOutlined1} ${buttons.primary} ${buttons.selected}`,
        disabled: `${buttons.buttonOutlined1} ${buttons.primary} ${buttons.disabled}`
      }
    }
  }

  label1PrimaryClassName(): string {
    return `${labels.label1} ${labels.primary}`
  }

  headline1PrimaryClassName(): string {
    return `${headlines.headline1} ${headlines.primary}`
  }

  headline2PrimaryClassName(): string {
    return `${headlines.headline2} ${headlines.primary}`
  }

  headline3PrimaryClassName(): string {
    return `${headlines.headline3} ${headlines.primary}`
  }

  headline4PrimaryClassName(): string {
    return `${headlines.headline4} ${headlines.primary}`
  }

  headline5PrimaryClassName(): string {
    return `${headlines.headline5} ${headlines.primary}`
  }

  headline6PrimaryClassName(): string {
    return `${headlines.headline6} ${headlines.primary}`
  }

  headline7PrimaryClassName(): string {
    return `${headlines.headline7} ${headlines.primary}`
  }

  title1PrimaryClassName(): string {
    return `${titles.title1} ${titles.primary}`
  }

  body1LinkClassName(): string {
    return `${bodies.body1} ${bodies.link}`
  }

  body1PrimaryClassName(): string {
    return `${bodies.body1} ${bodies.primary}`
  }

  body2PrimaryClassName(): string {
    return `${bodies.body2} ${bodies.primary}`
  }

  body3ErrorClassName(): string {
    return `${bodies.body3} ${bodies.error}`
  }

  body4SecondaryClassName(): string {
    return `${bodies.body4} ${bodies.secondary}`
  }

  body5SecondaryClassName(): string {
    return `${bodies.body5} ${bodies.secondary}`
  }

  body6SecondaryClassName(): string {
    return `${bodies.body6} ${bodies.secondary}`
  }

  info1DefaultClassName(): string {
    return `${info.info1} ${info.default}`
  }

  input1PrimaryClassName(): string {
    return `${inputs.input1} ${inputs.primary}`
  }

  input1PrimaryActiveClassName(): string {
    return `${inputs.input1} ${inputs.primary} ${inputs.active}`
  }

  input1PrimaryDisabledClassName(): string {
    return `${inputs.input1} ${inputs.primary} ${inputs.disabled}`
  }

  input1PrimaryPlaceholderClassName(): string {
    return `${inputs.input1} ${inputs.primary} ${inputs.placeholder}`
  }

  input1PrimaryErrorsClassName(): string {
    return `${inputs.input1} ${inputs.primary} ${inputs.hasErrors}`
  }

  input2PrimaryClassName(): string {
    return `${inputs.input2} ${inputs.primary}`
  }

  input2PrimaryActiveClassName(): string {
    return `${inputs.input2} ${inputs.primary} ${inputs.active}`
  }

  input2PrimaryDisabledClassName(): string {
    return `${inputs.input2} ${inputs.primary} ${inputs.disabled}`
  }

  input2PrimaryPlaceholderClassName(): string {
    return `${inputs.input2} ${inputs.primary} ${inputs.placeholder}`
  }

  input2PrimaryErrorsClassName(): string {
    return `${inputs.input2} ${inputs.primary} ${inputs.hasErrors}`
  }

  checkbox1ClassName(): string {
    return `${checkboxes.checkbox1}`
  }

  checkboxLabel1PrimaryClassName(): string {
    return `${checkboxes.checkboxLabel1} ${checkboxes.primary}`
  }

  chip1PrimaryClassName(): string {
    return `${chips.chip1} ${chips.primary}`
  }

  chip1PrimaryClearItemClassName(): string {
    return `${chips.clear}`
  }

  menu1PrimaryDefaultClassName(): string {
    return `${menus.menu1} ${menus.primary}`
  }

  menu1PrimaryActiveClassName(): string {
    return `${menus.menu1} ${menus.primary} ${menus.active}`
  }

  tableClassName(): string {
    return `${tables.table}`
  }

  tableMessageContainerClassName(): string {
    return `${tables.errorMessageContainer}`
  }

  tableHeader1PrimaryClassName(): string {
    return `${tables.tableHeader1} ${tables.primary}`
  }

  tableRow1PrimaryClassName(): string {
    return `${tables.tableRow1} ${tables.primary}`
  }

  tableRow1SecondaryClassName(): string {
    return `${tables.tableRow1} ${tables.secondary}`
  }

  tableRow2PrimaryClassName(): string {
    return `${tables.tableRow2} ${tables.primary}`
  }

  tableRow2SecondaryClassName(): string {
    return `${tables.tableRow2} ${tables.secondary}`
  }

  tableRow2LinkClassName(): string {
    return `${tables.tableRow2} ${tables.link}`
  }

  tableRow2ErrorClassName(): string {
    return `${tables.tableRow2} ${tables.error}`
  }

  objectClassName(): string {
    return `${objects.object}`
  }

  objectHeaderClassName(): string {
    return `${objects.objectHeader}`
  }

  objectContentClassName(): string {
    return `${objects.objectContent}`
  }

  objectFooterClassName(): string {
    return `${objects.objectFooter}`
  }

  dropdownClassName(): string {
    return `${dropdowns.dropdown}`
  }

  dropdownItem1PrimaryClassName(): string {
    return `${dropdowns.dropdownItem1} ${dropdowns.primary}`
  }

  dropdownItem1PrimarySelectedClassName(): string {
    return `${dropdowns.dropdownItem1} ${dropdowns.primary} ${dropdowns.selected}`
  }

  dropdownItem1PrimaryDisabledClassName(): string {
    return `${dropdowns.dropdownItem1} ${dropdowns.primary} ${dropdowns.disabled}`
  }

  dropdownItem1SecondaryClassName(): string {
    return `${dropdowns.dropdownItem1} ${dropdowns.secondary}`
  }

  dropdownItem2PrimaryClassName(): string {
    return `${dropdowns.dropdownItem2} ${dropdowns.primary}`
  }

  dropdownItem2PrimarySelectedClassName(): string {
    return `${dropdowns.dropdownItem2} ${dropdowns.primary} ${dropdowns.selected}`
  }

  dropdownItem2PrimaryDisabledClassName(): string {
    return `${dropdowns.dropdownItem2} ${dropdowns.primary} ${dropdowns.disabled}`
  }

  calendarItem1PrimaryClassName(): string {
    return `${calendars.calendarItem1} ${calendars.primary}`
  }

  calendarItem1PrimarySelectedClassName(): string {
    return `${calendars.calendarItem1} ${calendars.primary} ${calendars.selected}`
  }

  calendarItem1SecondaryClassName(): string {
    return `${calendars.calendarItem1} ${calendars.secondary}`
  }

  calendarItem2PrimaryClassName(): string {
    return `${calendars.calendarItem2} ${calendars.primary}`
  }

  calendarItem2PrimarySelectedClassName(): string {
    return `${calendars.calendarItem2} ${calendars.primary} ${calendars.selected}`
  }

  calendarItem2SecondaryClassName(): string {
    return `${calendars.calendarItem2} ${calendars.secondary}`
  }

  input1PrimaryStyle(): TextInputComponentStyle {
    return {
      classes: {
        inputDefault: this.input1PrimaryClassName(),
        inputActive: this.input1PrimaryActiveClassName(),
        inputDisabled: this.input1PrimaryDisabledClassName(),
        inputErrors: this.input1PrimaryErrorsClassName(),
        inputPlaceholder: this.input1PrimaryPlaceholderClassName()
      }
    }
  }

  input2PrimaryStyle(): TextInputComponentStyle {
    return {
      classes: {
        inputDefault: this.input2PrimaryClassName(),
        inputActive: this.input2PrimaryActiveClassName(),
        inputDisabled: this.input2PrimaryDisabledClassName(),
        inputErrors: this.input2PrimaryErrorsClassName(),
        inputPlaceholder: this.input2PrimaryPlaceholderClassName()
      }
    }
  }

  searchBar1PrimaryStyle(): TextInputComponentStyle {
    return {
      ...this.input1PrimaryStyle(),
      icons: {
        leftIconDefault: this.icSearchComponent()
      }
    }
  }

  selectPicker1PrimaryStyle(): PickerComponentStyles {
    return {
      input: {
        ...this.input1PrimaryStyle(),
        icons: {
          rightIconDefault: this.icExpandMoreComponent(),
          rightIconActive: this.icExpandMoreActiveComponent(),
          rightIconDisabled: this.icExpandMoreDisabledComponent()
        }
      },
      clearButton: {
        styles: this.buttonFilled1ClearStyle(),
        iconClassName: this.clearIconClassName()
      },
      dropdown: {
        dropdownClassName: this.dropdownClassName(),
        options: {
          classes: {
            optionDefault: this.dropdownItem1PrimaryClassName(),
            optionSelected: this.dropdownItem1PrimarySelectedClassName(),
            optionDisabled: this.dropdownItem1PrimaryDisabledClassName()
          }
        }
      }
    }
  }

  selectPicker2PrimaryStyle(): PickerComponentStyles {
    return {
      input: {
        ...this.input2PrimaryStyle(),
        icons: {
          rightIconDefault: this.icExpandMoreComponent(),
          rightIconActive: this.icExpandMoreActiveComponent(),
          rightIconDisabled: this.icExpandMoreDisabledComponent()
        }
      },
      dropdown: {
        dropdownClassName: this.dropdownClassName(),
        options: {
          classes: {
            optionDefault: this.dropdownItem2PrimaryClassName(),
            optionSelected: this.dropdownItem2PrimarySelectedClassName(),
            optionDisabled: this.dropdownItem2PrimaryDisabledClassName()
          }
        }
      }
    }
  }

  datePicker1PrimaryStyle(): DatePickerComponentStyle {
    return {
      input: {
        ...this.input1PrimaryStyle(),
        icons: {
          rightIconDefault: this.icCalendarDefaultComponent(),
          rightIconActive: this.icCalendarActiveComponent()
        }
      },
      clearButton: {
        styles: this.buttonFilled1ClearStyle(),
        iconClassName: this.clearIconClassName()
      },
      dropdownClassName: this.dropdownClassName(),
      calendar: {
        icons: {
          previousMonthIcon: this.icArrowLeftFilledComponent(),
          nextMonthIcon: this.icArrowRightFilledComponent()
        },
        selectPicker: {
          ...this.selectPicker2PrimaryStyle()
        },
        classes: {
          weekDay: this.calendarItem1SecondaryClassName(),
          day: this.calendarItem1PrimaryClassName(),
          currentDay: this.calendarItem2PrimaryClassName(),
          otherMonthDay: this.calendarItem1SecondaryClassName(),
          otherMonthCurrentDay: this.calendarItem2SecondaryClassName(),
          selectedDay: this.calendarItem1PrimarySelectedClassName(),
          selectedCurrentDay: this.calendarItem2PrimarySelectedClassName()
        }
      }
    }
  }

  segmentControl1PrimaryStyle(): SegmentControlComponentStyles {
    return {
      classes: {
        default: `${segments.segment1} ${segments.primary}`,
        selected: `${segments.segment1} ${segments.primary} ${segments.selected}`
      }
    }
  }
}
