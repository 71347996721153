import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CurrentFuelCompanyBalancesDomainDiModule from "./CurrentFuelCompanyBalancesDomainDiModule"
import CurrentFuelCompanyBalancesI18nDiModule from "./CurrentFuelCompanyBalancesI18nDiModule"
import BalanceTransactionType from "../../../../core/domain/balance-transactions/BalanceTransactionType"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"
import ObjectsDomainDiModule from "../../../../../admin/features/objects/di/modules/ObjectsDomainDiModule"
import CurrentFuelCompanyBalancePresenter
  from "../../presentation/pages/fuel-company-balance/CurrentFuelCompanyBalancePresenter"
import CurrentFuelCompanyBalancePageRouteElement
  from "../../presentation/pages/fuel-company-balance/CurrentFuelCompanyBalancePageRouteElement"
import CurrentFuelCompanyBalanceTransactionsTableProvider
  from "../../presentation/table-providers/CurrentFuelCompanyBalanceTransactionsTableProvider"
import CurrentFuelCompanyBalanceChangeDocumentsTableProvider
  from "../../presentation/table-providers/CurrentFuelCompanyBalanceChangeDocumentsTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"

const moduleKey = "currentFuelCompanyBalances"

export default interface CurrentFuelCompanyBalancesPresentationDiModule {
  provideCurrentFuelCompanyBalancePage(): React.ReactNode
}

export class DefaultCurrentFuelCompanyBalancesPresentationDiModule
  extends DiModule
  implements CurrentFuelCompanyBalancesPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly fuelCompanyBalancesI18nDiModule: CurrentFuelCompanyBalancesI18nDiModule
  private readonly fuelCompanyBalancesDomainDiModule: CurrentFuelCompanyBalancesDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly appI18nDiModule: AppI18nDiModule
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly fuelCompanyBalancesI18nDiModule: CurrentFuelCompanyBalancesI18nDiModule
    readonly fuelCompanyBalancesDomainDiModule: CurrentFuelCompanyBalancesDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.appI18nDiModule = parameters.appI18nDiModule
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.fuelCompanyBalancesI18nDiModule = parameters.fuelCompanyBalancesI18nDiModule
    this.fuelCompanyBalancesDomainDiModule = parameters.fuelCompanyBalancesDomainDiModule
  }

  provideCurrentFuelCompanyBalancePage(): React.ReactNode {
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <CurrentFuelCompanyBalancePageRouteElement
        appI18n={this.appI18nDiModule.provideAppI18n()}
        currentFuelCompanyBalancesI18n={this.fuelCompanyBalancesI18nDiModule.provideCurrentFuelCompanyBalancesI18n()}
        providePresenter={({
          transactionsType
        }) => this.provideFuelCompanyBalancePresenter({
          key: moduleKey,
          transactionsType
        })}
      />
    )
  }

  protected provideFuelCompanyBalancePresenter({
    key,
    transactionsType
  }: {
    readonly key: string
    readonly transactionsType?: BalanceTransactionType | null
  }): CurrentFuelCompanyBalancePresenter {
    return new CurrentFuelCompanyBalancePresenter({
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      subscribeToObjectsEventsUseCase:
        this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
          key
        }),
      unsubscribeFromObjectsEventsUseCase:
        this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
          key
        }),
      getCurrentFuelCompanyBalanceTransactionsUseCase:
        this.fuelCompanyBalancesDomainDiModule.provideGetCurrentFuelCompanyBalanceTransactionsUseCase(),
      getCurrentFuelCompanyBalanceChangeDocumentsUseCase:
        this.fuelCompanyBalancesDomainDiModule.provideGetCurrentFuelCompanyBalanceChangeDocumentsUseCase(),
      getFuelCompanyUseCase:
        this.fuelCompanyBalancesDomainDiModule.provideGetFuelCompanyForCurrentFuelCompanyBalancesUseCase(),
      transactionsType,
      fuelCompanyBalanceChangeDocumentsTableProvider:
        this.provideFuelCompanyBalanceChangeDocumentsTableProvider(),
      fuelCompanyBalanceTransactionsTableProvider:
        this.provideFuelCompanyBalanceTransactionsTableProvider()
    })
  }

  // eslint-disable-next-line max-len
  private provideFuelCompanyBalanceChangeDocumentsTableProvider(): CurrentFuelCompanyBalanceChangeDocumentsTableProvider {
    return this.single(
      CurrentFuelCompanyBalanceChangeDocumentsTableProvider.name,
      () => new CurrentFuelCompanyBalanceChangeDocumentsTableProvider({
        currentFuelCompanyBalancesI18n: this.fuelCompanyBalancesI18nDiModule.provideCurrentFuelCompanyBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }

  // eslint-disable-next-line max-len
  private provideFuelCompanyBalanceTransactionsTableProvider(): CurrentFuelCompanyBalanceTransactionsTableProvider {
    return this.single(
      CurrentFuelCompanyBalanceTransactionsTableProvider.name,
      () => new CurrentFuelCompanyBalanceTransactionsTableProvider({
        currentFuelCompanyBalancesI18n: this.fuelCompanyBalancesI18nDiModule.provideCurrentFuelCompanyBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }
}
