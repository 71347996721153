import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import FuelCompaniesI18 from "../../i18n/FuelCompaniesI18"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import formatPhoneNumber from "../../../../../admin/lib/formatPhone"
import AppI18 from "../../../../core/i18n/AppI18"

export default class FuelCompanyInfoProvider implements InfoProvider<FuelCompany> {
  private readonly appI18n: AppI18
  private readonly fuelCompaniesI18: FuelCompaniesI18

  constructor(parameters: {
    readonly appI18n: AppI18
    readonly fuelCompaniesI18: FuelCompaniesI18
  }) {
    this.appI18n = parameters.appI18n
    this.fuelCompaniesI18 = parameters.fuelCompaniesI18
  }

  getEntity(): string {
    return Entity.FUEL_COMPANIES
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: FuelCompany
  }): string {
    if (isBlank(object)) return this.fuelCompaniesI18.getTextProvider().existObjectTitle()

    const { name } = object

    return isPresent(name) ? name : this.fuelCompaniesI18.getTextProvider().existObjectTitle()
  }

  getObjectTitle({
    object
  }: {
    readonly object?: FuelCompany
  }): string {
    if (isBlank(object)) return this.fuelCompaniesI18.getTextProvider().existObjectTitle()

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.fuelCompaniesI18.getTextProvider().existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: FuelCompany
  }): InfoRow[] {
    const fuelCompaniesTextProvider = this.fuelCompaniesI18.getTextProvider()

    return filterNotEmpty([
      new StringInfoRow({
        name: "balance",
        title: fuelCompaniesTextProvider.balanceField(),
        value: (() => {
          const { balance } = object
          if (isBlank(balance)) return null

          const { formattedValue } = balance
          return formattedValue && withRubbleSymbol(formattedValue)
        })()
      }),
      new StringInfoRow({
        name: "creditLimitValue",
        title: fuelCompaniesTextProvider.creditLimitValueField(),
        value: (() => {
          const { creditLimit } = object
          if (isBlank(creditLimit)) return this.appI18n.getTextProvider().withoutCreditLimit()

          const { formattedValue } = creditLimit
          if (isBlank(formattedValue)) {
            return this.appI18n.getTextProvider().withoutCreditLimit()
          }

          return formattedValue && withRubbleSymbol(formattedValue)
        })()
      }),
      isPresent(object.creditLimit?.value) && isPresent(object.overall) ?
        new StringInfoRow({
          name: "overallValue",
          title: fuelCompaniesTextProvider.overallField(),
          value: (() => {
            return object.formattedOverall && withRubbleSymbol(object.formattedOverall)
          })()
        }) :
        null,
      isPresent(object.creditLimit?.value) && isPresent(object.debt) ?
        new StringInfoRow({
          name: "overallValue",
          title: fuelCompaniesTextProvider.debtField(),
          value: (() => {
            return object.formattedDebt && withRubbleSymbol(object.formattedDebt)
          })()
        }) :
        null,
      isPresent(object.creditLimit) ?
        new StringInfoRow({
          name: "creditLimitPeriodType",
          title: fuelCompaniesTextProvider.creditLimitPeriodTypeField(),
          value: (() => {
            const { creditLimit } = object
            if (isBlank(creditLimit)) return null

            return creditLimit.periodTypeSelectionOption?.text
          })(),
          additionalValue: (() => {
            const { creditLimit } = object
            if (isBlank(creditLimit?.periodType)) return null

            return this.appI18n.getTextProvider().creditLimitPeriodTypeDescription()
          })()
        }) : null,
      new StringInfoRow({
        name: "legalEntity",
        title: fuelCompaniesTextProvider.legalEntityField(),
        value: object.legalEntity?.name
      }),
      new StringInfoRow({
        name: "individualTaxpayerNumber",
        title: fuelCompaniesTextProvider.legalEntityIndividualTaxpayerNumberField(),
        value: object.legalEntity?.individualTaxpayerNumber
      }),
      new StringInfoRow({
        name: "taxRegistrationReasonCode",
        title: fuelCompaniesTextProvider.legalEntityTaxRegistrationReasonCode(),
        value: object.legalEntity?.taxRegistrationReasonCode
      }),
      new StringInfoRow({
        name: "contactPerson",
        title: fuelCompaniesTextProvider.contactPersonField(),
        value: object.contactPerson?.name,
        additionalValue: (() => {
          const { contactPerson } = object
          if (isBlank(contactPerson)) return null

          const { phoneNumber, email } = contactPerson
          return filterNotEmpty([
            formatPhoneNumber(phoneNumber),
            email
          ])
            .join(", ")
        })()
      }),
      new StringInfoRow({
        name: "blocked",
        title: fuelCompaniesTextProvider.statusField(),
        value: (() => {
          const { blocked = false } = object

          return (blocked ?? false) ?
            fuelCompaniesTextProvider.statusValueBlocked() :
            fuelCompaniesTextProvider.statusValueActive()
        })()
      })
    ])
  }

  getLinks({ object }: {
    readonly object: FuelCompany
  }): LinkInfo[] {
    const urlProvider = new AppUrlProvider()
    const fuelCompaniesTextProvider = this.fuelCompaniesI18.getTextProvider()

    return [
      {
        id: "refuellingOrders",
        entity: Entity.REFUELLING_ORDERS,
        title: fuelCompaniesTextProvider.refuellingOrdersLink(),
        url: urlProvider.buildRefuellingOrdersUrl({
          fuelCompanyId: object.id
        })
      },
      {
        id: "gasStations",
        entity: Entity.GAS_STATIONS,
        title: fuelCompaniesTextProvider.gasStationsLink(),
        url: urlProvider.buildGasStationsUrl({
          fuelCompanyId: object.id
        })
      },
      {
        id: "installationInstances",
        entity: Entity.INSTALLATION_INSTANCES,
        title: fuelCompaniesTextProvider.installationInstancesLink(),
        url: urlProvider.buildInstallationInstancesUrl({
          fuelCompanyId: object.id
        })
      },
      {
        id: "balance",
        entity: Entity.FUEL_COMPANY_BALANCE_REPLENISHMENTS,
        title: fuelCompaniesTextProvider.balanceLink(),
        url: urlProvider.buildFuelCompanyBalanceUrl({
          fuelCompanyId: object.id!
        })
      },
      {
        id: "drivers",
        entity: Entity.DRIVERS,
        title: fuelCompaniesTextProvider.usersLink(),
        url: urlProvider.buildFuelCompanyUsersUrl({
          fuelCompanyId: object.id
        })
      }
    ]
  }

  getEditUrl({
    object
  }: {
    readonly object: FuelCompany
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditFuelCompanyUrl({
      fuelCompanyId: object.id!
    })
  }
}
