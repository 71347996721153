import CreditLimitPeriodTypesRepository
  from "../../domain/repositories/CreditLimitPeriodTypesRepository"
import DefaultCreditLimitTypesRepository
  from "../../data/repositories/DefaultCreditLimitTypesRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"

export default interface CreditLimitPeriodTypesCoreDataDiModule {
  provideCreditLimitPeriodTypesRepository(): CreditLimitPeriodTypesRepository
}

export class DefaultCreditLimitPeriodTypesCoreDataDiModule
  extends DiModule
  implements CreditLimitPeriodTypesCoreDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCreditLimitPeriodTypesRepository(): CreditLimitPeriodTypesRepository {
    return this.single(
      DefaultCreditLimitTypesRepository.name,
      () => new DefaultCreditLimitTypesRepository({
        creditLimitPeriodTypesStaticSource: this.appDataDiModule.provideCreditLimitPeriodTypesStaticSource()
      })
    )
  }
}
