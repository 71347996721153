import Presenter from "../../../../../lib/presenter/Presenter"
import ObjectsListView, {
  AbstractObjectsListViewState,
  FilterValueByFilterId,
  FilterViewState,
  ObjectsListViewState,
  SearchViewState
} from "./ObjectsListView"
import GetObjectsUseCase, { GetObjectsResult } from "../../../domain/use-cases/objects/GetObjectsUseCase"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import TableProvider from "../../providers/TableProvider"
import Table from "../../entities/tables/Table"
import SubscribeToObjectsEventsUseCase from "../../../domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase from "../../../domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsEvent, { ObjectsEventCallback } from "../../../domain/entities/ObjectsEvent"
import assertNever from "../../../../../lib/assertNever"
import Page from "../../../../../core/domain/entities/pages/Page"
import autoBind from "auto-bind"
import { TableColumnSortingType } from "../../entities/tables/TableColumnSortingType"
import Sort from "../../entities/tables/Sort"
import FormField, { FormFieldViewState } from "../../entities/forms/FormField"
import Filter from "../../entities/filters/Filter"
import isPresent from "../../../../../lib/isPresent"
import CheckPermissionDeniedUseCase
  from "../../../../../core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import TableProviderUtils from "../../providers/TableProviderUtils"
import isBlank from "../../../../../lib/isBlank"

const queryInputDebounceTimeoutInMilliseconds = 300
const updateObjectsTimeoutInMilliseconds = 3_000

export default class ObjectsListPresenter<
  DomainObject,
  FilterObject = never
> extends Presenter<ObjectsListView<DomainObject, FilterObject>> {

  private readonly getObjectsUseCase: GetObjectsUseCase<DomainObject, FilterObject>
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly table: Table<DomainObject, FilterObject>
  private readonly tableProvider: TableProvider<DomainObject, FilterObject>
  private readonly filterValueByFilterId?: FilterValueByFilterId
  private objectsEventsCallback?: ObjectsEventCallback
  private listViewState?: ObjectsListViewState<DomainObject, FilterObject>
  private searchViewState?: SearchViewState
  private filterViewState?: FilterViewState
  private objects?: DomainObject[]
  private filterObject?: FilterObject
  private currentFilterObject?: FilterObject
  private page?: Page
  private query?: string
  private needReloadObjectsOnReAttach: boolean
  private queryInputTimeout?: NodeJS.Timeout
  private updateObjectsTimeout?: NodeJS.Timeout
  private isNextPageLoading = false
  private sort?: Sort
  private lastObjectsLoadingTimestamp?: number
  private searchByQueryIsEnable: boolean
  private tableProviderUtils: TableProviderUtils<DomainObject, FilterObject>

  constructor(parameters: {
    readonly getObjectsUseCase: GetObjectsUseCase<DomainObject, FilterObject>
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
    readonly tableProvider: TableProvider<DomainObject, FilterObject>
    readonly sort?: Sort
    readonly query?: string
    readonly filterValueByFilterId?: FilterValueByFilterId
  }) {
    super()

    autoBind(this)

    this.getObjectsUseCase = parameters.getObjectsUseCase
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.needReloadObjectsOnReAttach = false
    this.tableProvider = parameters.tableProvider
    this.table = parameters.tableProvider.getTable()
    this.searchByQueryIsEnable = parameters.tableProvider.searchByQueryIsEnable()
    this.sort = parameters.sort
    this.query = parameters.query
    this.filterValueByFilterId = parameters.filterValueByFilterId
    this.filterObject = this.table.getFilter()?.buildFilterObject(this.filterValueByFilterId)
    this.currentFilterObject = this.table.getFilter()?.buildFilterObject(this.filterValueByFilterId)
    this.tableProviderUtils = this.buildTableProviderUtils(parameters.checkPermissionDeniedUseCase)
    this.tableProviderUtils.initTableByPermissions()
    this.initFilterFields()
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    if (!this.tableProviderUtils.isTableVisibleByPermission()) {
      this.setAndShowForbiddenListViewState()
      return
    }

    const initialize = async(): Promise<void> => {
      this.setAndShowInitializingSearchViewState()
      this.setAndShowInitializingFilterViewState()
      this.setAndShowInitializingListViewState()

      await this.initializeFilter()
      this.subscribeToObjectsEvents()

      this.setAndShowIdleSearchViewState()
      this.setAndShowIdleFilterViewState()

      this.cacheCurrentFilterObject()

      await this.loadAndShowObjectsFirstPage()
    }

    initialize().then()
  }

  protected onViewReAttach() {
    super.onViewReAttach()

    this.showListViewState()
    this.showSearchViewState()
    this.showFilterViewState()

    // TODO: reload objects if related objects changed, not only current object-form type.
    if (this.getNeedReloadObjectsOnReAttach()) {
      this.setNeedReloadObjectsOnReAttach(false)
      this.loadAndShowObjectsFirstPage()
        .then()
    }
  }

  protected onDestroy() {
    super.onDestroy()
    this.unsubscribeFromObjectsEvents()
  }

  protected onDetachView() {
    super.onDetachView()

    clearTimeout(this.updateObjectsTimeout)
  }

  onQueryChanged(query: string) {
    this.setQuery(query)
    this.setAndShowIdleSearchViewState()

    clearTimeout(this.queryInputTimeout)
    this.queryInputTimeout = setTimeout(() => {
      this.loadAndShowObjectsFirstPage()
        .then()
      this.cacheQueryInView()
    }, queryInputDebounceTimeoutInMilliseconds)
  }

  onSearchRequested() {
    this.loadAndShowObjectsFirstPage()
      .then()
  }

  onNextPageRequested() {
    const hasMore = this.page?.hasMore
    if (hasMore && !this.isNextPageLoading) {
      this.loadAndShowObjectsNextPage()
        .then()
    }
  }

  onRetryLoadClicked() {
    const objectsSize = this.objects?.length ?? 0
    if (objectsSize > 0) {
      this.loadAndShowObjectsNextPage().then()
    } else {
      this.loadAndShowObjectsFirstPage().then()
    }
  }

  onColumnClicked(columnId: string) {
    this.changeSort(columnId)
    this.cacheSortInView()
    this.loadAndShowObjectsFirstPage()
      .then()
  }

  onApplyFilterClicked(): void {
    this.cacheFilterInView()
    this.cacheCurrentFilterObject()
    this.setAndShowIdleFilterViewState()
    this.loadAndShowObjectsFirstPage()
      .then()
  }

  onResetFilterClicked(): void {
    // TODO: needs pass reset through fields?
    this.setFilterObject(this.table.getFilter()!.buildFilterObject(this.filterValueByFilterId))
    this.setAndShowIdleFilterViewState()
    this.cacheFilterInView()
    this.cacheCurrentFilterObject()
    this.loadAndShowObjectsFirstPage()
      .then()
  }

  private initFilterFields() {
    this
      .table
      .getFilter()
      ?.getFields()
      ?.forEach((filterField: FormField<FilterObject, never>) => {
        filterField.setSetAndShowLoadedFuelCompanyBalanceChangeDocumentViewState(this.setAndShowIdleFilterViewState)
        filterField.setSetObject(this.setFilterObject)
        filterField.setCacheObject(this.cacheFilterInView)
      })
  }

  private subscribeToObjectsEvents() {
    this.objectsEventsCallback = this.subscribeToObjectsEventsUseCase.call((event: ObjectsEvent) => {
      switch (event.type) {
        case "created":
        case "updated":
        case "destroyed":
          this.setNeedReloadObjectsOnReAttach(true)
          break
        default:
          assertNever(event)
      }
    })
  }

  private unsubscribeFromObjectsEvents() {
    this.objectsEventsCallback && this.unsubscribeFromObjectsEventsUseCase.call(this.objectsEventsCallback)
  }

  private async initializeFilter(): Promise<void> {
    const filter: Filter<FilterObject> | undefined = this.table.getFilter()

    if (isPresent(filter) && isPresent(this.filterValueByFilterId)) {
      const filterFields: FormField<FilterObject, never>[] = filter.getFields()

      for (const filterField of filterFields) {
        await filterField.init({
          object: this.filterObject!,
          serializedValue: this.filterValueByFilterId[filterField.getId(this.filterObject!)]
        })
      }
    }
  }

  private async loadAndShowObjectsFirstPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.setObjects(undefined)
    this.setPage(undefined)
    this.setAndShowLoadingListViewState()

    const result: GetObjectsResult<DomainObject> = await this.getObjectsUseCase.call({
      query: this.query,
      sort: this.sort,
      filter: this.currentFilterObject
    })

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setAndShowLoadingErrorListViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowLoadingFailureListViewState({ exception: result.exception })
        break
      case "success":
        this.setObjects(result.data.objects)
        this.setPage(result.data.page)
        this.setAndShowLoadedListViewState()
        this.startPeriodicUpdateIfNeed()
        break
    }
  }

  private async loadAndShowObjectsNextPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.isNextPageLoading = true
    this.setAndShowNextPageLoadingListViewState()

    const lastDomainObjectIndex: number = this.objects!.length - 1
    const lastDomainObject: DomainObject = this.objects![lastDomainObjectIndex]

    const result: GetObjectsResult<DomainObject> = await this.getObjectsUseCase.call({
      query: this.query,
      sort: this.sort,
      filter: this.currentFilterObject,
      pagination: {
        id: this.table.getObjectId(lastDomainObject)
      }
    })

    this.isNextPageLoading = false

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setAndShowNextPageLoadingErrorListViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowNextPageLoadingFailureListViewState({ exception: result.exception })
        break
      case "success":
        this.setObjects([...this.objects!, ...result.data.objects])
        this.setPage(result.data.page)
        this.setAndShowLoadedListViewState()
        this.startPeriodicUpdateIfNeed()
        break
    }
  }

  private startPeriodicUpdateIfNeed() {
    clearTimeout(this.updateObjectsTimeout)

    this.updateObjectsTimeout = setTimeout(async() => {
      const updatingObjectIds: string[] = []

      this.objects?.forEach(object => {
        if (this.table.needRefreshObject?.(object) === true) {
          const objectId = this.table.getObjectId(object)
          updatingObjectIds.push(objectId)
        }
      })
      if (updatingObjectIds.length === 0) return

      const result = await this.getObjectsUseCase.call({
        needPagination: false,
        ids: updatingObjectIds
      })

      if (result.type === "success") {
        const newObjects = result.data.objects
        newObjects.forEach((newObject) => {
          const newObjectId = this.table.getObjectId(newObject)
          const indexForUpdating = this.objects?.findIndex((oldObject) => {
            return this.table.getObjectId(oldObject) === newObjectId
          })

          if (isBlank(indexForUpdating)) return
          this.objects![indexForUpdating] = newObject
          this.setAndShowLoadedListViewState()
        })
        this.startPeriodicUpdateIfNeed()
      }
    }, updateObjectsTimeoutInMilliseconds)
  }

  private setAndShowInitializingSearchViewState() {
    this.setAndShowSearchViewState({
      type: "initializing"
    })
  }

  private setAndShowIdleSearchViewState() {
    this.setAndShowSearchViewState({
      type: "idle",
      query: this.query
    })
  }

  private setAndShowInitializingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "initializing"
    })
  }

  private setAndShowLoadingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading"
    })
  }

  private setAndShowLoadingErrorListViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading_error",
      error
    })
  }

  private setAndShowLoadingFailureListViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading_failure",
      exception
    })
  }

  private setAndShowLoadedListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loaded",
      objects: this.objects!,
      page: this.page!
    })
  }

  private setAndShowNextPageLoadingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading",
      objects: this.objects!
    })
  }

  private setAndShowNextPageLoadingErrorListViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading_error",
      objects: this.objects!,
      error
    })
  }

  private setAndShowNextPageLoadingFailureListViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading_failure",
      objects: this.objects!,
      exception
    })
  }

  private setAndShowForbiddenListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "forbidden"
    })
  }

  private getAbstractListViewStateParameters(): AbstractObjectsListViewState<DomainObject, FilterObject> {
    return {
      table: this.table,
      sort: this.sort,
      searchByQueryIsEnable: this.searchByQueryIsEnable
    }
  }

  private setAndShowListViewState(listViewState: ObjectsListViewState<DomainObject, FilterObject>) {
    this.setListViewState(listViewState)
    this.showListViewState()
  }

  private showListViewState() {
    this.listViewState && this.getView()
      ?.showObjectsListViewState(this.listViewState)
  }

  private setListViewState(listViewState: ObjectsListViewState<DomainObject, FilterObject>) {
    this.listViewState = listViewState
  }

  private setAndShowSearchViewState(searchViewState: SearchViewState) {
    this.setSearchViewState(searchViewState)
    this.showSearchViewState()
  }

  private setSearchViewState(searchViewState: SearchViewState) {
    this.searchViewState = searchViewState
  }

  private showSearchViewState() {
    this.searchViewState && this.getView()
      ?.showSearchViewState(this.searchViewState)
  }

  private setAndShowIdleFilterViewState() {
    this.setAndShowFilterViewState({
      type: "idle",
      filterFieldViewStates: this
        .table
        .getFilter()
        ?.getFields()
        ?.map((formField: FormField<FilterObject, never>): FormFieldViewState => {
          return formField.getViewState(this.filterObject!, undefined)
        }) ?? []
    })
  }

  private setAndShowInitializingFilterViewState() {
    this.setAndShowFilterViewState({
      type: "initializing"
    })
  }

  private setAndShowFilterViewState(filterViewState: FilterViewState) {
    this.setFilterViewState(filterViewState)
    this.showFilterViewState()
  }

  private setFilterViewState(filterViewState: FilterViewState) {
    this.filterViewState = filterViewState
  }

  private showFilterViewState() {
    this.filterViewState && this.getView()
      ?.showFilterViewState(this.filterViewState)
  }

  private cacheQueryInView() {
    this.getView()
      ?.cacheQuery(this.query)
  }

  private cacheSortInView() {
    this.getView()
      ?.cacheSort(this.sort)
  }

  private cacheCurrentFilterObject() {
    this.currentFilterObject = {
      ...this.filterObject
    } as FilterObject
  }

  private cacheFilterInView() {
    const filterValueByFilterId: FilterValueByFilterId = {}
    const filter: Filter<FilterObject> = this.table.getFilter()!
    const filterFields: FormField<FilterObject, never>[] = filter.getFields()

    filterFields.forEach((value: FormField<FilterObject, never>) => {
      filterValueByFilterId[value.getId(this.filterObject!)] = value.serializeValue(this.filterObject!)
    })

    this.getView()
      ?.cacheFilterValueByFilterId(filterValueByFilterId)
  }

  private setObjects(objects: DomainObject[] | undefined) {
    this.objects = objects
  }

  private setPage(page: Page | undefined) {
    this.page = page
  }

  private setQuery(query: string) {
    this.query = query
  }

  private setFilterObject(filterObject: FilterObject) {
    this.filterObject = filterObject
  }

  private getNeedReloadObjectsOnReAttach() {
    return this.needReloadObjectsOnReAttach
  }

  private setNeedReloadObjectsOnReAttach(needReloadObjectsOnReAttach: boolean) {
    this.needReloadObjectsOnReAttach = needReloadObjectsOnReAttach
  }

  private changeSort(columnId: string) {
    const column = this.table.getColumns()
      .find((column) => column.getName() === columnId)

    const reversedSortOrder = column?.isReversedSortOrder() ?? false
    const currentSortType = this.sort?.id === columnId ? this.sort?.type : undefined
    const changedSortType = (() => {
      switch (currentSortType) {
        case TableColumnSortingType.ASC:
          return reversedSortOrder ? undefined : TableColumnSortingType.DESC
        case TableColumnSortingType.DESC:
          return reversedSortOrder ? TableColumnSortingType.ASC : undefined
        case undefined:
          return reversedSortOrder ? TableColumnSortingType.DESC : TableColumnSortingType.ASC
        default:
          return undefined
      }
    })()

    this.sort = changedSortType ? { id: columnId, type: changedSortType } : undefined
  }

  private buildTableProviderUtils(checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase) {
    return new TableProviderUtils<DomainObject, FilterObject>({
      tableProvider: this.tableProvider,
      table: this.table,
      checkPermissionDeniedUseCase: checkPermissionDeniedUseCase
    })
  }
}
