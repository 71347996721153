import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CreditLimitPeriodTypesRepository
  from "../repositories/CreditLimitPeriodTypesRepository"
import { CreditLimitPeriodTypeSelectOption } from "../../../../core/domain/credit-limit/CreditLimitPeriodType"

export interface GetCreditLimitPeriodTypesParameters extends GetObjectsParameters {
}

export default class GetCreditLimitPeriodTypesUseCase implements
  GetObjectsUseCase<CreditLimitPeriodTypeSelectOption> {

  private readonly creditLimitPeriodTypesRepository:
    CreditLimitPeriodTypesRepository

  constructor(parameters: {
    readonly creditLimitPeriodTypesRepository:
      CreditLimitPeriodTypesRepository
  }) {
    this.creditLimitPeriodTypesRepository = parameters.creditLimitPeriodTypesRepository
  }

  async call(
    parameters: GetCreditLimitPeriodTypesParameters
  ): Promise<GetObjectsResult<CreditLimitPeriodTypeSelectOption>> {
    return await this.creditLimitPeriodTypesRepository.getCreditLimitPeriodTypes(parameters)
  }
}
