import DiModule from "../../../../../admin/lib/di/DiModule"
import CreditLimitPeriodTypesCoreDataDiModule from "./CreditLimitPeriodTypesCoreDataDiModule"
import GetCreditLimitPeriodTypesUseCase
  from "../../domain/use-cases/GetCreditLimitPeriodTypesUseCase"

export default interface CreditLimitPeriodTypesCoreDomainDiModule {
  provideGetCreditLimitPeriodTypesUseCase(): GetCreditLimitPeriodTypesUseCase
}

export class DefaultCreditLimitPeriodTypesCoreDomainDiModule
  extends DiModule
  implements CreditLimitPeriodTypesCoreDomainDiModule {

  private readonly creditLimitPeriodTypesCoreDataDiModule: CreditLimitPeriodTypesCoreDataDiModule

  constructor(parameters: {
    readonly creditLimitPeriodTypesCoreDataDiModule: CreditLimitPeriodTypesCoreDataDiModule
  }) {
    super()
    this.creditLimitPeriodTypesCoreDataDiModule = parameters.creditLimitPeriodTypesCoreDataDiModule
  }

  provideGetCreditLimitPeriodTypesUseCase(): GetCreditLimitPeriodTypesUseCase {
    return this.single(
      GetCreditLimitPeriodTypesUseCase.name,
      () => new GetCreditLimitPeriodTypesUseCase({
        creditLimitPeriodTypesRepository:
          this.creditLimitPeriodTypesCoreDataDiModule.provideCreditLimitPeriodTypesRepository()
      })
    )
  }
}
