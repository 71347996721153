import getTimeZone from "./getTimeZone"

const yearCharactersCount = 4
const monthCharactersCount = 2
const dayCharactersCount = 2
const hoursCharactersCount = 2
const minutesCharactersCount = 2
const secondsCharactersCount = 2
const millisecondsCharactersCount = 3

const millisecondsInSecond = 1000
const secondsInMinute = 60
const minutesInHour = 60

const timeZoneSignIndex = 0
const timeZoneHourFirstIndex = 1
const timeZoneHourSecondIndex = 2
const timeZoneMinuteFirstIndex = 4
const timeZoneMinuteSecondIndex = 5

// TODO: leave here only lib methods. Create app wrapper with specific logic outside

export default class DateTimeFormatter {
  private readonly date: Date
  private readonly timeZone: string

  constructor(date: Date, timeZone?: string) {
    this.date = date
    this.timeZone = timeZone ?? getTimeZone(date)
  }

  formatDateTime(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    return `${day}.${month}.${year} ${hours}:${minutes}`
  }

  formatDate(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    return `${day}.${month}.${year}`
  }

  formatTime(): string {
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    return `${hours}:${minutes}`
  }

  formatISODate(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    return `${year}-${month}-${day}`
  }

  formatISOTime(): string {
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    const seconds = this.getSerializedSeconds()
    const milliSeconds = this.getSerializedMilliseconds()
    return `${hours}:${minutes}:${seconds}.${milliSeconds}`
  }

  formatHoursMinutes(): string {
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    return `${hours}:${minutes}`
  }

  getSerializedFullYear(): string {
    return this.getFullYear().toString()
      .padStart(yearCharactersCount, "0")
  }

  getSerializedMonth(): string {
    return this.getMonth().toString()
      .padStart(monthCharactersCount, "0")
  }

  getSerializedMonthDay(): string {
    return this.getMonthDay().toString()
      .padStart(dayCharactersCount, "0")
  }

  getSerializedHours(): string {
    return this.getHours().toString()
      .padStart(hoursCharactersCount, "0")
  }

  getSerializedMinutes(): string {
    return this.getMinutes().toString()
      .padStart(minutesCharactersCount, "0")
  }

  getSerializedSeconds(): string {
    return this.getSeconds().toString()
      .padStart(secondsCharactersCount, "0")
  }

  getSerializedMilliseconds(): string {
    return this.getMilliseconds().toString()
      .padStart(millisecondsCharactersCount, "0")
  }

  getFullYear(): number {
    return (this.dateWithOffset()).getFullYear()
  }

  getMonth(): number {
    return (this.dateWithOffset()).getMonth() + 1
  }

  getMonthDay(): number {
    return (this.dateWithOffset()).getDate()
  }

  getHours(): number {
    return (this.dateWithOffset()).getHours()
  }

  getMinutes(): number {
    return (this.dateWithOffset()).getMinutes()
  }

  getSeconds(): number {
    return (this.dateWithOffset()).getSeconds()
  }

  getMilliseconds(): number {
    return (this.dateWithOffset()).getMilliseconds()
  }

  private dateWithOffset(): Date {
    const utcDate: Date = new Date(
      this.date.getUTCFullYear(),
      this.date.getUTCMonth(),
      this.date.getUTCDate(),
      this.date.getUTCHours(),
      this.date.getUTCMinutes(),
      this.date.getUTCSeconds(),
      this.date.getUTCMilliseconds()
    )

    const sign: number = this.timeZone[timeZoneSignIndex] === "+" ? 1 : -1
    const hoursOffset: number = parseInt(`${this.timeZone[timeZoneHourFirstIndex]}${this.timeZone[timeZoneHourSecondIndex]}`)
    const minutesOffset: number = parseInt(`${this.timeZone[timeZoneMinuteFirstIndex]}${this.timeZone[timeZoneMinuteSecondIndex]}`)
    const offsetInMilliseconds: number = sign * (
      hoursOffset * minutesInHour * secondsInMinute * millisecondsInSecond +
      minutesOffset * secondsInMinute * millisecondsInSecond
    )

    return new Date(utcDate.getTime() + offsetInMilliseconds)
  }
}
